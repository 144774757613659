// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from "react";

export const chart = {
	symbols: [["ETH.D ", "CRYPTOCAP:ETH.D|12M"]],
	chartOnly: true,
	width: "100%",
	height: "100%",
	locale: "kr",
	colorTheme: "dark",
	autosize: false,
	showVolume: false,
	showMA: false,
	hideDateRanges: false,
	hideMarketStatus: false,
	hideSymbolLogo: false,
	scalePosition: "right",
	scaleMode: "Normal",
	fontFamily: "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
	fontSize: "10",
	noTimeScale: false,
	valuesTracking: "1",
	changeMode: "price-and-percent",
	chartType: "line",
	dateFormat: "MM/dd/yyyy",
};

function DominanceChartEth(props) {
	const contariner = useRef();

	useEffect(() => {
		if (!contariner) return;
		const script = document.createElement("script");
		script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
		script.type = "text/javascript";
		script.async = true;
		chart.colorTheme = props.isDarkMode ? "dark" : "light";
		script.innerHTML = JSON.stringify(chart);
		while (contariner.current.hasChildNodes()) {
			contariner.current.removeChild(contariner.current.firstChild);
		}
		contariner.current.appendChild(script);
		document.body.className = (props.isDarkMode ? ['bodyBG_drk'] : ['bodyBG_lgt']);
	}, [props.isDarkMode]);

	return (
		<div className="tradingview-widget-container" ref={contariner}>
			<div className="tradingview-widget-container__widget"></div>
			{/* <a href="https://kr.tradingview.com/symbols/BTC.D/?exchange=CRYPTOCAP" rel="noopener" target="_blank">
				<span className="blue-text">ETH.D 환율</span>
			</a> */}
		</div>
	);
}
export default DominanceChartEth;
