/* sortJsonData, try에서 setTraders, setAscending, log(newTrader.length) 호출 catch에서 log(er) 호출
onColumnClick에서 sortJsonData 호출 / walletStateMapping에서 log(props.walletState) 호출 
SetPriceUnitConvert에서 //walletState 출금정보 확인로직 필요함
//소켓데이터 useEffect, {}에서 connectionBinace, connectionUpbit, if문에서 setTraders 호출
connectionBinace에서  addEventListener("message", binance_onMessage), addEventListener("close", () => {
			if (binance_wss) {
				binance_wss.removeEventListener("message", binance_onMessage);
				binance_wss.removeEventListener("close", binance_onClose);
			}
			binance_onClose(result);
connectionUpbit에서 위와 동일 패턴 + addEventListener("open", (e) => Upbit_Open(e, result)); 추가

tryValueChange에서 try에 SetPriceUnitConvert(data.acc_trade_price_24h) 호출 catch에 log("tryValueChange:" + ex) 호출
upBit_onClose에서 log("upBit WebSocket connection closed. Retrying..."), setTimeout(() => {connectionUpbit();}, 100);
binance_onClose에서 log("binace WebSocket connection closed. Retrying..."), 
setTimeout(() => {connectionBinace(result);}, 100); 호출 / binance_onMessage / 
upBit_onMessage에서 try에 tryValueChange(data) catch에 log("connectionFails::==" + ex + "try Reconnection ==") 호출
Upbit_Open / Coins / ItemRender / 
return에서 onColumnClick('korean_name',coinRows), onColumnClick('currentValue',coinRows), onColumnClick('Kimp',coinRows), 
onColumnClick('prevDay',coinRows), onColumnClick('lower_price',coinRows), onColumnClick('highPrice',coinRows), 
onColumnClick('day',coinRows), ItemRender(traders), setRenderCnt(renderCnt + 15) 호출

*/
import react, { useEffect, useState,useContext } from "react";
import upbit from "../../resource/images/icon-upbit.png";
import DynamicChart from "../../widget/DynamicChart";
import "../../css/scss/component/CoinDropDownInfo.scss";
import * as property from "./CoinDropDownInfoProperty";
import * as action from "./CoinDropDownInfoAction";
import * as utils from "../../utils";
import ReactDOM from 'react-dom';

//JSON OBJECT 1
//{"market_warning":"CAUTION","market":"KRW-WAVES","korean_name":"웨이브","english_name":"Waves","coins":"WAVES","ssid":"wavesusdt@trade/","symbol":"BINANCE:WAVESUSDTPERP","isDisplay":false}
 const CoinDropDownInfo =(props) => {
	let initializeScreen ={ itemList : false , USD_KRW : false, walletState : false};
	const [traders, setTraders] = useState(props.itemList);//코인 정보
	const [nameAscending, setNameAscending] = useState(true); //필터 정렬을 위한 변수
	const [currentAscending, setCurrentAscending] = useState(true); //필터 정렬을 위한 변수
	const [kimpAscending, setKimpAscending] = useState(true); //필터 정렬을 위한 변수
	const [prevDayAscending, setPrevDayAscending] = useState(true); //필터 정렬을 위한 변수
	const [lowerPriceAscending, setLowerPriceAscending] = useState(true); //필터 정렬을 위한 변수
	const [highPriceAscending, setHighPriceAscending] = useState(true); //필터 정렬을 위한 변수
	const [dayAscending, setDayAscending] = useState(true); //필터 정렬을 위한 변수
	const [renderCnt, setRenderCnt] = useState(15);//렌더링 갯수
	const [disableButton, setDisableButton] = useState(false);//렌더링 갯수
	const [filter, setFilter] = useState('');//코인 정보
	useEffect(() => {
		 action.initialization(property); 
		}, []);
	//소켓 데이터
	useEffect(() => {
		if(!props.itemList || !props.USD_KRW || !props.walletState)
			return;
		else{
			const result = action.makeSocketParams(props.itemList,props.walletState);
			if(!result) return;
			property.setState('USD_KRW', props.USD_KRW); 
			//walletState 출금정보 확인로직 필요함
			action.connectionBinace(result,property.initState.binance_wss,property.initState.coinRows,props);
			action.connectionUpbit(result,property.initState.upbit_wss,props);
			if (result.length !== 0) {
				setTraders(result);
			}
		}

		
	}, [props.itemList,props.USD_KRW,props.walletState]);

	useEffect(() => {	
		
	}, [filter]);	
	useEffect(() => {	
		
	}, []);					


	const resetFilter=(e)=>{
		let parentElement= e.currentTarget.parentElement.children;
			for (let index = 0; index < parentElement.length; index++) {
				let element = parentElement[index];
				
				if(element.className === 'itme empty') {
				}
				else{
					element.className = 'itme';
				}
			}
		}
		const onFilter = (e,ssid) => {
			setFilter(e.target.value);
			let coinsRows = property.getState('coinRows');
			coinsRows[ssid].isBookMark = !coinsRows[ssid].isBookMark;
		}
		const onBookMark = (ssid,value) => {
			let coinsRows = property.getState('coinRows');
			coinsRows[ssid].isBookMark = value;
			let newArray = [];
			traders.forEach(element => {
				let ssid = element.ssid.replace('/','');
				let targetObject = coinsRows[ssid];
            if(targetObject){
					element.isBookMark = targetObject.isBookMark;
					element.currentValue = targetObject.currentValue;
					element.binaceValue = targetObject.binaceValue;
					element.day = targetObject.day;
					element.prevDay = targetObject.prevDay;
					element.kimp = targetObject.kimp;
					element.lower_price = targetObject.lower_price;
					element.lower_priceRate = targetObject.lower_priceRate;
					element.lowest = targetObject.lowest;
					element.lowestRate = targetObject.lowestRate;
					element.highest = targetObject.highest;
					element.highestRate = targetObject.highestRate;
				
				}
				newArray.push(element);
			});
			setTraders(newArray);
		}
	if (traders === false) 
		return;
	return (
		<div className="CoinDropDownInfo-Contants">
			<div className="CoinDropDownInfo-header">
				<div className="textInfo">
					<div> 
						<img src={upbit} />
						<div className="title">현물정보 / 코인별 김치프리미엄</div>
					</div>
					<div>
						<span className="material-symbols-outlined warning">warning</span>
						<span>유의</span>
						<span className="material-symbols-outlined error">block</span>
						<span>출금불가</span>
					</div>
				</div>
				{/* input-box안 input이 포커스되어있는 경우: <div className="input-box focus"> input에서 포커스 해제되면 .focus 제거되어야함  */}
				<div className="input-box">
					<span className="material-symbols-outlined">search</span>
					<input type="text" value={filter} placeholder="입력" onChange={(e)=>onFilter(e)}></input>
				</div>
			</div>
			{/* 최소 리스트 정렬: 거래대금순! */}
			<div className="CoinDropDownInfo-body">
				<div className="itmeHeader">
					<div className="itme empty"></div>
					<div className="itme"  onClick ={(e) => action.onColumnClick('korean_name',traders,property.getState('coinRows'),nameAscending, setNameAscending,setTraders,e,resetFilter)}>
						<span>이름</span>
						<div className="iconColumn-box">
							<span className="material-symbols-outlined">arrow_drop_up</span>
							<span className="material-symbols-outlined">arrow_drop_down</span>
						</div>
					</div>
					<div className="itme" onClick ={(e) => action.onColumnClick('currentValue',traders,property.getState('coinRows'),currentAscending, setCurrentAscending,setTraders,e,resetFilter)}>
						<span>현재가</span>
						<div className="iconColumn-box">
							<span className="material-symbols-outlined">arrow_drop_up</span>
							<span className="material-symbols-outlined">arrow_drop_down</span>
						</div>
					</div>
					<div className="itme" onClick ={(e) => action.onColumnClick('kimp',traders,property.getState('coinRows'),kimpAscending, setKimpAscending,setTraders,e,resetFilter)}>
						<span>김프</span>
						<div className="iconColumn-box">
							<span className="material-symbols-outlined">arrow_drop_up</span>
							<span className="material-symbols-outlined">arrow_drop_down</span>
						</div>
					</div>
					<div className="itme" onClick ={(e) => action.onColumnClick('prevDay',traders,property.getState('coinRows'),prevDayAscending, setPrevDayAscending,setTraders,e,resetFilter)}>
						<span >전일대비</span>
						<div className="iconColumn-box">
							<span className="material-symbols-outlined">arrow_drop_up</span>
							<span className="material-symbols-outlined">arrow_drop_down</span>
						</div>
					</div>
					<div className="itme" onClick ={(e) => action.onColumnClick('lower_price',traders,property.getState('coinRows'),lowerPriceAscending, setLowerPriceAscending,setTraders,e,resetFilter)}>
						<span>저가대비(52주)</span>
						<div className="iconColumn-box">
							<span className="material-symbols-outlined">arrow_drop_up</span>
							<span className="material-symbols-outlined">arrow_drop_down</span>
						</div>
					</div>
					<div className="itme"  onClick ={(e) => action.onColumnClick('highest',traders,property.getState('coinRows'),highPriceAscending, setHighPriceAscending,setTraders,e,resetFilter)}>
						<span>고가대비(52주)</span>
						<div className="iconColumn-box">
							<span className="material-symbols-outlined">arrow_drop_up</span>
							<span className="material-symbols-outlined">arrow_drop_down</span>
						</div>
					</div>
					{/* 클릭하는 경우: <div className="itme active_upArrow">로 변경되면서 정렬도 변경되어야함 */}
					<div className="itme" onClick ={(e) => action.onColumnClick('day',traders,property.getState('coinRows'),dayAscending,setDayAscending,setTraders,e,resetFilter)}>
						<span >거래대금</span>
						<div className="iconColumn-box">
							<span className="material-symbols-outlined">arrow_drop_up</span>
							<span className="material-symbols-outlined">arrow_drop_down</span>
						</div>
					</div>
				</div>
				{
					traders.map((item, i) => {
						if(item.isBookMark) {
							if((i < traders.length )){
												if(filter ===''){
														return(<DynamicChart
														getTopSendKimp={props.getTopSendKimp}
														coins = {item.coins}
														english_name = {item.english_name}
														korean_name = {item.korean_name}
														market = {item.market}
														market_warning = {item.market_warning}
														wallet_state = {item.wallet_state}
														ssid={item.ssid}
														symbol={item.symbol}
														isBookMark={item.isBookMark}
														onBookMark = {(ssid,value)=>onBookMark(ssid,value)}
														key={i} id={"tradingview_row_" + i.toString()} 
														/>);
												}
												else{
													const regex = utils.createFuzzyMatcher(filter);
													regex.test(item);
													let isTrue = regex.test(item.korean_name);
												
													if (isTrue) {
														return(<DynamicChart 
														getTopSendKimp={props.getTopSendKimp}
														coins = {item.coins}
														english_name = {item.english_name}
														korean_name = {item.korean_name}
														market = {item.market}
														market_warning = {item.market_warning}
														wallet_state = {item.wallet_state}
														ssid={item.ssid}
														symbol={item.symbol}
														isBookMark={item.isBookMark}
														onBookMark = {(ssid,value)=> onBookMark(ssid,value)}
														key={i} id={"tradingview_row_" + i.toString()} 
														/>);
													}
											}
										}
						}
					})
				}
				{/* { ItemRender(traders) } */}
				{
					traders.map((item, i) => {
						if(!item.isBookMark===true) {
							if((i < traders.length )){
								if(i < renderCnt){
												if(filter ===''){
														return(<DynamicChart 
														getTopSendKimp={props.getTopSendKimp}
														coins = {item.coins}
														english_name = {item.english_name}
														korean_name = {item.korean_name}
														market = {item.market}
														market_warning = {item.market_warning}
														wallet_state = {item.wallet_state}
														ssid={item.ssid}
														symbol={item.symbol}
														isBookMark={item.isBookMark}
														onBookMark = {(ssid,value)=>onBookMark(ssid,value)}
														key={i} id={"tradingview_row_" + i.toString()} 
														/>);
												}
												else{
													const regex = utils.createFuzzyMatcher(filter);
													regex.test(item);
													let isTrue = regex.test(item.korean_name);
												
													if (isTrue) {
														return(<DynamicChart 
														getTopSendKimp={props.getTopSendKimp}
														coins = {item.coins}
														english_name = {item.english_name}
														korean_name = {item.korean_name}
														market = {item.market}
														market_warning = {item.market_warning}
														wallet_state = {item.wallet_state}
														ssid={item.ssid}
														symbol={item.symbol}
														isBookMark={item.isBookMark}
														onBookMark = {(ssid,value)=> onBookMark(ssid,value)}
														key={i} id={"tradingview_row_" + i.toString()} 
														/>);
													}
											}
										}
					}}
					})
				}
			</div>
			{!disableButton?<div className="btn-part">
			<button  type="button" onClick={() => {setRenderCnt(renderCnt + 15);if(traders.length<renderCnt){ setDisableButton(true)} }}>
					<span className="material-symbols-outlined">arrow_drop_down</span>코인 더보기
				</button>
			</div>:<div></div>}
		</div>
	);
}
export default CoinDropDownInfo;
