import { React, useEffect, useRef } from "react";

function TickerTape(props) {
  const onLoadScriptRef = useRef();
  useEffect(() => {
    onLoadScriptRef.current = getWidget();
  }, [props.isDarkMode])
  
  const getWidget = () => {
		const scriptDom = document.createElement("script");
		scriptDom.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
		scriptDom.type = "text/javascript";
		scriptDom.async = true;
		scriptDom.rel = "noopener";
		scriptDom.target = "_blank";
    scriptDom.crossOrigin = "anonymous";
    //처음엔 div.tradingview-widget-container 의 자식으로 넘어옴
    scriptDom.innerHTML = `{
        "symbols": [
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500"
          },
          {
            "proName": "FOREXCOM:NSXUSD",
            "title": "US 100"
          },
          {
            "proName": "USD",
            "title": "EUR/USD"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "비트코인"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "이더리움"
          }
        ],
        "showSymbolLogo": true,
        "colorTheme": "`;
    scriptDom.innerHTML += props.isDarkMode ? "dark" : "white";
    scriptDom.innerHTML +=`",
    "isTransparent": true,
    "displayMode": "adaptive",
    "locale": "kr"
      }`;

      if (onLoadScriptRef.current) {
        onLoadScriptRef.current.appendChild(scriptDom);
      }
      
	};
	return (
    <div className= "tradingview-widget-container">
			{/* <a href="https://kr.tradingview.com/symbols/NASDAQ-AAPL/technicals/" rel="noopener" target="_blank"> */}
			{/* <span className="blue-text">AAPL 주식 분석</span> */}
      {/* </a> */}
      <div ref={onLoadScriptRef}></div>
		</div>
	);
}
export default TickerTape;
