import React, { useEffect, useState } from "react";
import * as Api from "../../Api";
//import './index.css';
import "../../css/scss/component/ResistanceZone.scss";

export default function ResistanceZone(props) {

	if (props.data === {}) {
		return <>로딩중</>;
	} else {
		return (
			<div className="percent-bar">
				<div className="title">
					BTC 데일리 <span className="long">지지</span>/<span className="short">저항</span>
				</div>
				<span className={props.isDarkMode ? "dark" : "light"}>
					{props.data.swingShort ? (
						props.data.swingShort.map((item, i) => {
							return (
								<label key={i}>
									<p>{item.start === '' ? '' : Number(item.start).toLocaleString()}{(item.start !== '' || item.end !== '') ? '~' : ''}{item.end === '' ? '' : Number(item.end).toLocaleString()}
									</p>
								</label>
							);
						})
					) : (
						<></>
					)}
				</span>
				<div className="title">
					ETH 데일리 <span className="long">지지</span>/<span className="short">저항</span>
				</div>
				<span className={props.isDarkMode ? "dark" : "light"}>
					{props.data.swingLong ? (
						props.data.swingLong.map((item, i) => {
							return (
								<label key={i}>
									<p>{item.start === '' ? '' : Number(item.start).toLocaleString()}{(item.start !== '' || item.end !== '') ? '~' : ''}{item.end === '' ? '' : Number(item.end).toLocaleString()}
									</p>
								</label>
							)
						})
					) : (
						<></>
					)}
				</span>
			</div>
		);
	}
}