export const API = process.env.REACT_APP_API_URL;
export const API_URL = process.env.REACT_APP_API_URL;

export const getBitcoin24info = (setApiData)=>{
    fetch(API)
    .then(res => res.json())
    .then((msg) => {setApiData(msg)})
    .catch(err => {});
}
//restAPI
export const getBitcoin = (setApiData)=>{
    
    fetch(API)
    //fetch('/api/bitcoin',{credentials:'include'})
    .then(res => res.json())
    .then((msg) => {
        // 페이지에 목록을 뿌려줄 때 index가 0인 데이터는 빼고 뿌려줘야함
        setApiData(msg)
    })
    .catch(err => {});
}

export const getUpbitApi = (setApiList) => {
    fetch('https://api.upbit.com/v1/market/all?isDetails=true')
    .then(res => res.json())
        .then((msg) => { setApiList(msg)})
    .catch(err=>{});
}

export const getWallet_state = (setWalletState) => {
    fetch('https://ccx.upbit.com/api/v1/status/wallet')
    .then(res => res.json())
        .then((msg) => { setWalletState(msg)})
    .catch(err=> {});
}
export const getFearAndGreed=(setMeterChartValue)=>{
    fetch('https://api.alternative.me/fng/')
    .then(res => res.json())
    .then((msg) =>  {setMeterChartValue(msg)})
    .catch(err=> {});
}

export const whaletransaction=(setTraders)=>{
    fetch(API_URL + '/getwhaletransaction',{credentials:'include'})
    .then(res => res.json())
    .then((msg) => {
       let array = [];
        for (let index = 0; index < msg.list.length; index++) {
            const element = msg.list[index]; // {Number(trader.krwAmount).toLocaleString()}
            let item = { 
                'type': element.type, 
                'name': element.from+' → '+element.to, 
                'money': Number(element.krwAmount).toLocaleString() + " ￦", 
                'symbol': Number(element.coinAmount).toLocaleString() +' '+element.symbol, 
                'date': element.date }
            setTraders(current=>[...current,item]); 
        }
    })
    .catch(err=> {});
}

export const getList = (setTraders) => {
    fetch(API_URL + '/getlist', {credentials:"include"})
    .then(res => res.json())
    .then((msg) => { 
        let array = [];
        let swingLong = msg.swingLong;
        let swingShort = msg.swingShort;
        let whaleTransaction = msg.whaleTransaction;
        let streamer = msg.streamer;
        let readerBord = msg.readerBord;

        for (let index = 0; index < whaleTransaction.length; index++) {
           const element = whaleTransaction[index]; // {Number(trader.krwAmount).toLocaleString()}
            let date = element.date; // 2023-01-01 00:00:00
            date = date.substring(6, date.length).replace("-", "/");

            let item = { 
                'type': element.type, 
                'name': element.from+' → '+element.to, 
                'money': Number(element.krwAmount), 
                'symbol': Number(element.coinAmount).toLocaleString() +' '+element.symbol, 
                'date': date
            }
            
            whaleTransaction[index] = item;
        }
        array.swingLong = swingLong;
        array.swingShort = swingShort;
        array.whaleTransaction = whaleTransaction;
        array.streamer = streamer;
        array.readerBord = readerBord;
        array.newReaderBord = readerBord.filter((item, index) => index !== 0);

        setTraders(array);
     })
    .catch(err => {});
}

//서버 경유 호출 코드
export const reqNickname = (nick) => {
    fetch('/api/reqNickname', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({nick:nick})
    }
    )
        .then(res => res.json())
        .then((msg) => { })
        .catch(err => {});
}

//다이렉트 호출코드
export const getFundingRate = (uri,result,symbols) => {
    return new Promise((resolve,reject)=>{
    fetch(uri)
    .then(res => res.json())
     .then((msg) => { 
                result[symbols] = msg;
                resolve(); 
             })
        .catch(err => { });
    })
}

//다이렉트 호출코드
    export const getLongShort = (setLongShort, period)=>{
        fetch(`https://fapi.binance.com/futures/data/globalLongShortAccountRatio?symbol=BTCUSDT&period=${period}&limit=1`)
        .then(res => res.json())
        .then((msg) => {setLongShort(msg)})
        .catch(err=>{});
    }

    export const getTopLongShort = (setTopLongShort, period)=>{
        fetch(`https://fapi.binance.com/futures/data/topLongShortPositionRatio?symbol=BTCUSDT&period=${period}&limit=1`)
        .then(res => res.json())
        .then((msg) => {setTopLongShort(msg)})
        .catch(err=>{});
    }
