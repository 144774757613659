


export const ENDPOINT = process.env.REACT_APP_ENDPOINT;

export let socket={
    connection:undefined,
};

export let connectionInfo={
    socketid:'',
    name:'',
    room:'',
}

export const setMyNiceName = (value)=>{
    connectionInfo.name = value;
}

export let logText = [];

export let name = '';

export let nick = '';

export let room = 'bitcoin24';