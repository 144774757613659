import { useState, useRef, useEffect } from "react";
import "../css/scss/component/popup.scss";
import { checkStrLen } from '../utils';

function Popup(props) {
	const [isDarkMode, setIsDarkMode] = useState(true);
	const [isSound,setIsSound] = useState();
	const [AccountValue, setAccountValue] = useState(props.AccountValue);
	const [sound, setSound] = useState(props.sound);
	const sliderRef = useRef(null);
	
	useEffect(() => {
		
	}, [props.sound]);

	useEffect(()=>{
		if(!props.IsSound=== undefined)
			setIsSound(props.IsSound());
	},[props.IsSound])
	

	return (
		<>
			{/* 도움말 팝업을 제외한 나머지들은 배경(.popup-bg)을 가지고 화면 정중앙 가운데 정렬!
			도움말 팝업의 경우 .popup-bg생략, 모바일 태블릿에서 물음표아이콘 터치할 경우 노출되고 피씨에서는 호버하는 경우에 노출됨 */}
			{/* popup-bg가 들어가는 팝업의 경우 팝업이 노출되어있는 경우 body에 .not-scroll를 추가했다가 팝업이 사라지면 body에 .not-scroll도 없어져야함 */}
			<div className={props.type === "help1" || props.type === "help2" ? "" : "popup-bg"}>
				{/* 도움말 팝업의 경우: <div className="popup helpPopup">, 나머지: <div className="popup">  */}
				<div className={isDarkMode ? "popup theme_drk" : "popup theme_lgt"}>
					<div className="popupHeader">
						<h2>{props.title}</h2>
					</div>
					{/* .popupbody에 .hide제거 시 노출됨 _ 도움말 팝업 */}
					{props.type === "help1" || props.type === "help2" ? <div className="popupbody typeHelp">{
						props.helpText.split('\r\n').map((line, i) => {
							return (<span key={i}>{line}<br /></span>)
						})
					}
					</div> : <></>
					}

					{/* 닉네임 설정 팝업 */}

					<div className={props.type === "NickName" ? "popupbody typeInput" : "popupbody typeInput hide"}>
						<input type="text" placeholder="입력" value={AccountValue} onChange={(e)=> setAccountValue(checkStrLen(e.target.value, 12))}/>
					</div>

					{props.type === "help1" || props.type === "help2" || props.type === "NickName" ? (
						<></>
					) : (
						<>
							<div className="popupbody typeAlarm">
								{/* 알림설정 팝업(실시간 고래 거래 체결 알림설정 팝업에만 금액이 들어감!) */}
								<div className="row">
									<span className="title">소리</span>
									<label>
										<input type="checkbox" checked={props.isCompleteSound} onChange={(e)=>props.setIsCompleteSound(e.target.checked)} hidden />
										<span className="slide-btn"></span>
									</label>
								</div>
								<div className="row">
									<span className="title">볼륨</span>
									<input
										type="range"
										min="0"
										max="100"
										value={props.volume}
										
										className="rangeBar rangeValue"
										onChange={(e) => {props.setVolume(e.target.value)}}
									/>
									{/* <div className="rangeBar">
										<div className="rangeValue" style={{ width: "50%" }}>
											<span className="ball"></span>
										</div>
									</div> */}
								</div>
								<div className={props.type === "Alarm2" ? "hide" : "row"}>
									<span className="title">금액</span>
									<div className="amountRange">
										<div className="d-flex">
											<span>설정값 :</span>
											<input type="text" placeholder="5" value={props.standardPrice} />
											<span>억</span>
										</div>
										<input
											type="range"
											min="1"
											max="150"
											defaultValue={1+(props.standardPrice*149)/100}
											className="rangeBar rangeValue"
											onChange={(e) => {
												/* 슬라이더 값 변경 처리 */
												props.setConclusionPrice(e.target.value);
											}}
										/>
										{/* <div ref={sliderRef} className="rangeBar">
											<div className="rangeValue" style={{ width: "0%" }}>
												<span className="ball"></span>
											</div>
										</div> */}
										<div className="d-flex">
											<span>1 억</span>
											<span>150 억</span>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
					<div className="popupFooter">
						<button
							type="button"
							className={props.type === "help1" || props.type === "help2" ? "hide" : "popupCancelBtn"}
							onClick={props.cancelAction}
						>
							취소
						</button>
						{ props.type === "NickName" &&
							<button type="button" className="popupBtn" onClick={()=>{props.okAction(AccountValue);}}>
							확인
							</button>
						}
						{ props.type !== "NickName" &&
							<button type="button" className="popupBtn" onClick={()=>{
								props.okAction(props.standardPrice,props.isCompleteSound);
								props.cancelAction();
								}}>
								확인
							</button>
						}
					</div>
				</div>
			</div>
		</>
	);
}

export default Popup;
