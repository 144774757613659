import React, { useState, useRef, useEffect } from 'react';


const TopCoinList = (props) => {
    const WIDGET_URL= process.env.REACT_APP_WIDGET_URL;
    const [src, setSrc] = useState('');  // src를 state로 변경
    const iframeRef = useRef(null);

    const handleMessage = (event) => {
        if (event.data && event.data.type === 'topcoinkimp') {
          iframeRef.current.style.height = `${event.data.height}px`;
        }
    };

    useEffect(() => {
        const modeCode = props.isDarkMode ? "dark" : "light"; // modeCode 계산을 useEffect 내부로 이동 
        setSrc(`${WIDGET_URL}/TopCoinKimp?modeCode=${modeCode}`);  

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                const frame = document.getElementById("topcoinkimp").contentWindow;
                frame.postMessage({ functionName: 'Reconnect' }, WIDGET_URL);
            }
        };

        if (props.mediaSize !== 'xlarge') {
            document.addEventListener("visibilitychange", handleVisibilityChange);
        }
        
        window.addEventListener('message', handleMessage);
    
        return () => {
            if (!props.mediaSize !== 'xlarge') {
                document.removeEventListener("visibilitychange", handleVisibilityChange);
            }
            
            window.removeEventListener('message', handleMessage);
        };
    }, [props.isDarkMode, props.mediaSize]);

    return (
        <div>
            <iframe
                ref={iframeRef}
                id="topcoinkimp"
                src={src}
                width="100%"
                height="0px"
                scrolling="no"
            />
        </div>
    );
}

export default TopCoinList;