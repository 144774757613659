import React, { useEffect } from "react";

const InfoBar = ({
	room,
	connectionInfo,
	isShowMenu,
	showChatRoom,
	setShowChatRoom,
	nickName,
	setNickname,
	setPopupinfo,
	setShoInfoHelper,
	showInfoHelper,
	UI,
	isChatFontZoom,
	setIsChatFontZoom,
}) => {
	useEffect(() => {}, [showChatRoom]);

	const zoomInOut = () => {
		// if (ischatFontZoom === false) {
		// 	ischatFontZoom = true;
		// } else {
		// 	ischatFontZoom = false;
		// }
		setIsChatFontZoom(!isChatFontZoom);
	};

	return (
		<div className="infoBar">
			<div className="userInfo">
				<span className="material-symbols-outlined">account_circle</span>
				{/* <div className="nickname">{connectionInfo.name}</div> */}
				<div className="nickname" style={{ cursor: "pointer" }} onClick={() => setNickname(setPopupinfo, setShoInfoHelper, showInfoHelper)}>
					{nickName}
				</div>
			</div>
			<div className="chattingSetting">
				{/* <img style={{ cursor: "pointer" }} src={closeIcon} alt="close" onClick={isShowMenu} /> */}
				<label>
					<input type="checkbox" hidden />
					<span className="text">크게 보기</span>
					<span className="slide-btn" onClick={() => zoomInOut()}></span>
				</label>
				<button
					type="button"
					className="chatBtn"
					onClick={() => {
						UI.setMessages([]);
					}}
				>
					<span className="material-symbols-outlined">delete_forever</span>
				</button>
				<button type="button" className="chatBtn">
					{/* 채팅창이 닫혀있을때 .rotate 추가 / 채팅창이 열려있을때 .rotate 제거 */}
					<span
						className={showChatRoom ? "material-symbols-outlined" : "material-symbols-outlined rotate"}
						onClick={() => {
							setShowChatRoom(!showChatRoom);
						}}
					>
						expand_more
					</span>
				</button>
			</div>
		</div>
	);
};
export default InfoBar;
