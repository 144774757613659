import { React, useEffect, useRef} from "react";
import "../../css/scss/component/CoinAnalysis.scss";

function CoinAnalysis(props) {
	const onLoadScriptRef = useRef();

	useEffect(() => {
		onLoadScriptRef.current = getWidget();
	}, [props.isDarkMode])
	
	const getWidget = () => {
		const scriptDom = document.createElement("script");
		scriptDom.src = "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
		scriptDom.type = "text/javascript";
		scriptDom.async = true;
		scriptDom.rel = "noopener";
		scriptDom.target = "_blank";
		scriptDom.crossOrigin = "anonymous";
		scriptDom.innerHTML = `{
			"interval": "5m",
			"width": "100%",
			"isTransparent": true,
			"height": "100%",
			"symbol": "BINANCE:BTCUSDTPERP",
			"showIntervalTabs": true,
			"locale": "kr",
			"colorTheme": "`;
			scriptDom.innerHTML += props.isDarkMode ? "dark" : "white";
			scriptDom.innerHTML +=`"
    	}`;

		if (onLoadScriptRef.current) {
			onLoadScriptRef.current.appendChild(scriptDom);
		}
	};
	return (
		<div className="chartCover">
			<div className="tradingview-widget-container">
				<div ref={onLoadScriptRef} className="fitContent" ></div>
			</div>
		</div>
	);
}
export default CoinAnalysis;
