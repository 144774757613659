import React from "react";

const Input = ({ message, setMessage, sendMessage }) => (
	<form className="input-form">
		<input
			placeholder="메세지를 입력하세요..."
			value={message}
			onChange={(event) => setMessage(event.target.value)}
			onKeyPress={(event) => (event.key === "Enter" ? sendMessage(event) : null)}
		/>
		<button className="sendButton" onClick={(event) => sendMessage(event)}>
			<span className="material-symbols-outlined">send</span>
		</button>
	</form>
);

export default Input;
