import React, { useEffect, useState, useRef, useContext } from "react";
import "../../css/scss/component/WhaleConclusion.scss";
import * as utils from "../../utils";

//
var spot = undefined;
var futures1 = undefined;
var futures2 = undefined;

let isConnection = false;

function WhaleConclusion({
	USD_KRW,
	changeAlarm1Data,
	setPopup,
	isCompleteSound,
	setIsCompleteSound,
	volume,
	setVolume,
	standardPrice,
	setStandardPrice,
}) {
	const volumeRef = useRef(volume);
	const priceRef = useRef(standardPrice);
	const isSoundRef = useRef(isCompleteSound);

	useEffect(() => {
		priceRef.current = standardPrice;
		volumeRef.current = volume;
		isSoundRef.current = isCompleteSound;
	}, [volume, standardPrice, isCompleteSound]);

	useEffect(() => {
		if (isConnection == false) {
			connectionSpot();
			connectionFutures1();
			connectionFutures2();
			isConnection = true;
		}
	}, []);

	const connectionSpot = () => {
		//현물정보 spot
		spot = new WebSocket("wss://stream.binance.com/stream?streams=btcusdt@aggTrade/btcbusd@aggTrade/btcusdc@aggTrade/btcdai@aggTrade");
		spot.addEventListener("message", spot_onmessage);
		spot.addEventListener("close", () => {
			if (spot) {
				spot.removeEventListener("message", spot_onmessage);
				spot.removeEventListener("close", spot_onClose);
			}
			spot_onClose();
		});
	};

	const spot_onmessage = (response) => {
		var data;

		data = response.data;

		if (data.indexOf("error") === -1) {
			let jData = JSON.parse(data)["data"];
			if (jData === undefined) return;

			let quantity = parseFloat(jData.q);
			let price = parseFloat(jData.p);
			let usdPrice = price * quantity;
			let krwPrice =  parseFloat(usdPrice) * USD_KRW;
			let checkPrice = priceRef.current * 100000000;

			if (krwPrice >= checkPrice) {
				setTraders((current) => [
					{ type: jData.m === true ? "매도" : "매수", name: "BTC 현물", money: krwPrice, dollar: usdPrice },
					...current,
				]);

				try {
					if (isSoundRef.current) {
						if (krwPrice >= 1000000000) {
							if (jData.m !== true) {
								const audio = new Audio('/buy10BillionUp.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							} else {
								const audio = new Audio('/sell10BillionUp.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							}
						} else {
							if (jData.m !== true) {
								const audio = new Audio('/buy10BillionDown.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							} else {
								const audio = new Audio('/sell10BillionDown.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							}
						}
					}
				} catch (ex) {

				}
			}
		}
		//		audio.muted = false;
	};
	const spot_onClose = () => {
		setTimeout(() => {
			connectionSpot();
		}, 1000);
	};

	const connectionFutures1 = () => {
		//선물정보 futures
		var uri1 = "wss://fstream.binance.com/stream?streams=btcusdt@aggTrade/btcbusd@aggTrade";
		futures1 = new WebSocket(uri1);

		futures1.addEventListener("message", futures1_onmessage);
		futures1.addEventListener("close", () => {
			if (futures1) {
				futures1.removeEventListener("message", futures1_onmessage);
				futures1.removeEventListener("close", futures1_onClose);
			}
			futures1_onClose();
		});
	};

	const futures1_onmessage = (response) => {
		var data;
		data = response.data;

		if (data.indexOf("error") === -1) {
			let jData = JSON.parse(data)["data"];
			if (jData === undefined) return;

			let quantity = parseFloat(jData.q);
			let price = parseFloat(jData.p);

			let usdPrice = price * quantity;
			let krwPrice = parseFloat(usdPrice) * USD_KRW;
			let checkPrice = priceRef.current * 100000000;

			if (krwPrice >= checkPrice) {
				setTraders((current) => [
					{ type: jData.m === true ? "매도" : "매수", name: "BTC 선물", money: krwPrice, dollar: usdPrice },
					...current,
				]);

				try {
					if (isSoundRef.current) {
						if (krwPrice >= 1000000000) {
							if (jData.m !== true) {
								const audio = new Audio('/buy10BillionUp.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							} else {
								const audio = new Audio('/sell10BillionUp.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							}
						} else {
							if (jData.m !== true) {
								const audio = new Audio('/buy10BillionDown.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							} else {
								const audio = new Audio('/sell10BillionDown.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							}
						}
					}
				} catch (ex) {
				}
			}
		}
	};
	const futures1_onClose = () => {
		setTimeout(() => {
			connectionFutures1();
		}, 1000);
	};

	//선물 2
	const connectionFutures2 = () => {
		var uri2 = "wss://dstream.binance.com/stream?streams=btcusd_perp@aggTrade";
		futures2 = new WebSocket(uri2);
		futures2.addEventListener("message", futures2_onmessage);
		futures2.addEventListener("close", () => {
			if (futures2) {
				futures2.removeEventListener("message", futures2_onmessage);
				futures2.removeEventListener("close", futures2_onClose);
			}
			futures2_onClose();
		});
	};
	const futures2_onmessage = (response) => {
		var data;
		data = response.data;

		if (data.indexOf("error") === -1) {
			let jData = JSON.parse(data)["data"];
			if (jData === undefined) return;
			let quantity = parseFloat(jData.q);
			let usdPrice = quantity * 100;
			let krwPrice = parseFloat(usdPrice) * USD_KRW;
			let checkPrice = priceRef.current * 100000000;

			if (krwPrice >= checkPrice) {
				setTraders((current) => [
					{ type: jData.m === true ? "매도" : "매수", name: "BTC 선물", money: krwPrice, dollar: usdPrice },
					...current,
				]);

				try {
					if (isSoundRef.current) {
						if (krwPrice >= 1000000000) {
							if (jData.m !== true) {
								const audio = new Audio('/buy10BillionUp.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							} else {
								const audio = new Audio('/sell10BillionUp.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							}
						} else {
							if (jData.m !== true) {
								const audio = new Audio('/buy10BillionDown.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							} else {
								const audio = new Audio('/sell10BillionDown.mp3');
								audio.volume = volumeRef.current * 0.01;
								audio.play();
							}
						}
					}
				} catch (ex) {
				}
			}
		}
	};
	const futures2_onClose = () => {
		setTimeout(() => {
			connectionFutures2();
		}, 1000);
	};

	const [traders, setTraders] = useState([]);

	const getStyle = (trader) => {
		let result = "";
		let isOver = trader.money >= 1000000000;

		if (trader.type === "매도") {
			result = isOver ? "item short" : "item short70";
		} else {
			result = isOver ? "item long" : "item long70";
		}

		return result;
	};

	return (
		<div className="WhaleConclusion-Contants">
			<div className="titleRow">
				<span className="title">
					실시간 고래 <span className="accent">체결</span> 알림
				</span>
				<div
					className="setting-icon"
					onClick={() =>
						setPopup({
							title: "실시간 고래 체결 알림팝업",
							type: "Alarm1",
							// sound :Sound,
							//setSound:setSound,

							isCompleteSound: isCompleteSound,
							setIsCompleteSound: setIsCompleteSound,
							volume: volume,
							setVolume: setVolume,
							standardPrice: standardPrice,
							setStandardPrice: setStandardPrice,

							IsSound: (e) => {
								return isCompleteSound;
							},
							AccountValue: (e) => volume,
							okAction: (isSound, volume, price) => {
								changeAlarm1Data(isSound, volume, price);
								setPopup(false);
							},
							cancelAction: () => setPopup(false),
						})
					}
				>
					<span className="material-symbols-outlined">settings</span>
				</div>
			</div>
			<div className="scrollBox">
				{traders.map((trader, i) => {
					return (
						<div key={i}>
							{/* 배경색 컨트롤 스타일: short70 short long70 long */}
							<div className={getStyle(trader)}>
								<div className="d-flex">
									<div className="traderType">{trader.type}</div>
									<div className="traderName">{trader.name}</div>
								</div>
								<div className="TraderMoney">{utils.SetPriceUnitConvert(trader.money)}</div>
								<div className="TraderDollar">{Number(Number(trader.dollar).toFixed(0)).toLocaleString() + " $"}</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default WhaleConclusion;
