import React, { CreateRef, useState, useEffect, createRef } from "react";
import * as action from "./FlatDropDownAction";
import * as property from "./FlatDropDownProperty";

function FlatDropDown(Props) {
	let [ItemSource, setItemSource] = useState(Props.ObjectArray);
	let DropDownBox = createRef();

	useEffect(() => {
		action.InitializieComponent(Props, DropDownBox);
		Props.onChange(Props.ObjectArray[0]);
	}, [DropDownBox.current]);

	useEffect(() => {
		setItemSource(Props.ObjectArray);
	}, [Props.ObjectArray]);

	return (
		<div ref={DropDownBox} className="FlatDropDown" style={{ width: Props.width }}>
			<select>
				{ItemSource.map((Row, index) => (
					<option key={index} value={index}>
						{Row}
					</option>
				))}
			</select>
		</div>
	);
}

export default FlatDropDown;
