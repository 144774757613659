import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// var AudioContext;
// var audioContext;
// window.onload = function() {
//   //console.log(navigator.mediaDevices);
//   navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
//       AudioContext = window.AudioContext || window.AudioContext;
//       audioContext = new AudioContext();
//   }).catch(e => {
//       console.error(`Audio permissions denied: ${e}`);
//   });
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
