import {escapeRegExp} from 'lodash';
//초성검색
function ch2pattern(ch) {
    const offset = 44032; /* '가'의 코드 */
    // 한국어 음절
    if (/[가-힣]/.test(ch)) {
      const chCode = ch.charCodeAt(0) - offset;
      // 종성이 있으면 문자 그대로를 찾는다.
      if (chCode % 28 > 0) {
        return ch;
      }
      const begin = Math.floor(chCode / 28) * 28 + offset;
      const end = begin + 27;
      return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 한글 자음
    if (/[ㄱ-ㅎ]/.test(ch)) {
      const con2syl = {
        'ㄱ': '가'.charCodeAt(0),
        'ㄲ': '까'.charCodeAt(0),
        'ㄴ': '나'.charCodeAt(0),
        'ㄷ': '다'.charCodeAt(0),
        'ㄸ': '따'.charCodeAt(0),
        'ㄹ': '라'.charCodeAt(0),
        'ㅁ': '마'.charCodeAt(0),
        'ㅂ': '바'.charCodeAt(0),
        'ㅃ': '빠'.charCodeAt(0),
        'ㅅ': '사'.charCodeAt(0),
      };
      const begin = con2syl[ch] || ( ( ch.charCodeAt(0) - 12613 /* 'ㅅ'의 코드 */ ) * 588 + con2syl['ㅅ'] );
      const end = begin + 587;
      return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 그 외엔 그대로 내보냄
    // escapeRegExp는 lodash에서 가져옴
    return escapeRegExp(ch);
  }
  export function createFuzzyMatcher(input) {
    const pattern = input.split('').map(ch2pattern).join('.*?');
    return new RegExp(pattern);
  }


// 숫자 한글로 축약하는 API 한글로 변환하는 도구 ex)
//value = number ex) 10000
//return = string ex) 10000 -> 1만
export function SetPriceUnitConvert(value) {
    //walletState 출금정보 확인로직 필요함
    try{


    value = Number(value).toFixed(0).toString();
    if (value.toString().indexOf(",") !== -1) {
        value = value.replace(/,/g, "");
    }

    var priceLen = value.length;
    var roundCount = 0;
    var divisionValue = "10000";
    var arrayPriceUnitCount = [3, 4, 8, 12, 16, 20, 24];
    var arrayPriceUnit = ["", "천", "만", "억", "조", "경", "해"];
    var priceUnit = "";
    var divisionLen = 0;

    for (var i = 0; i < arrayPriceUnitCount.length; i++) {
        if (priceLen <= arrayPriceUnitCount[i]) {
            priceUnit = arrayPriceUnit[i <= 1 ? 2 : i];
            divisionLen = arrayPriceUnitCount[i] - 8;
            break;
        }
    }
    }catch(ex){
    }

    divisionValue = divisionLen > 0 ? divisionValue.padEnd(divisionLen + divisionValue.length, "0") : (divisionValue += "");
    var result = (value / parseInt(divisionValue)).toFixed(roundCount);
    var amount = result;
    var float = "";

    if (result.toString().indexOf(".") != -1) {
        amount = parseInt(result.split(".")[0]).toLocaleString();
        float = "." + result.split(".")[1];
    }

    result = parseInt(amount).toLocaleString() + float + priceUnit;

    return result;
}

//ObjectData를 받아서 정렬하는 함수
//[참조별 호출 스텍] 
// 1. prop.itemList => traders =>coinRows[ssid]
// CoinDropDownInfoAction
// action.Coins(e, traders[i], traders[i].ssid.replace('/','')
export const sortJsonData = (column,traders,objectArray,ascending,setAscending,setResult) => {
	
    try{
        let newTrader=[];
        traders.forEach(element => {
            let ssid = element.ssid.replace('/','');
            let targetObject = objectArray[ssid];
            if(targetObject){
                //element.e = targetObject.e;
                element.isBookMark = targetObject.isBookMark;
                element.currentValue = targetObject.currentValue;
                element.binaceValue = targetObject.binaceValue;
                element.day = targetObject.day;
                element.prevDay = targetObject.prevDay;
                element.kimp = targetObject.kimp;
                element.lower_price = targetObject.lower_price;
                element.lower_priceRate = targetObject.lower_priceRate;
                element.lowest = targetObject.lowest;
                element.lowestRate = targetObject.lowestRate;
                element.highest = targetObject.highest;
                element.highestRate = targetObject.highestRate;
            }
            newTrader.push(element);
        });

        const newData = newTrader.sort(function(a, b) {
          
            // if (typeof a[column] === 'number') { //숫자
            if (column === 'korean_name') { //숫자
                //문자
                if (ascending) { //오름차순
                  const upperCaseA = a[column].toUpperCase();
                  const upperCaseB = b[column].toUpperCase();
                  if(upperCaseA > upperCaseB) return 1;
                  if(upperCaseA < upperCaseB) return -1;
                  if(upperCaseA === upperCaseB) return 0;
                } 
                else { //내림차순
                    const upperCaseA = a[column].toUpperCase();
                    const upperCaseB = b[column].toUpperCase();
                    if(upperCaseA < upperCaseB) return 1;
                    if(upperCaseA > upperCaseB) return -1;
                    if(upperCaseA === upperCaseB) return 0;
                }
            }else{
              if(ascending){
                return a[column] - b[column];
              }else{
                return b[column] - a[column];
              }
            }
          
		});

    
		setResult(newData);
		setAscending(!ascending);
	}
	catch(ex){
	}
  };

// 글자 수 제한
export function checkStrLen(str, maxByte) {
  let totalByte = 0;
  let result = '';

  for (let index = 0; index < str.length; index++) {
    let currentByte = str.charCodeAt(index);
    (currentByte > 128) ? totalByte += 2 : totalByte++;
  }

  if (totalByte > maxByte) {
    let byte = 0;
    for (let index = 0; index < str.length; index++) {
      let currentByte = str.charCodeAt(index);
      (currentByte > 128) ? byte += 2 : byte++;

      if (byte <= maxByte) {
        result += str.charAt(index);
      } 
    }
    return result;
  } else {
    return str;
  }

  
}