import React, { useEffect, useState } from "react";
import Bitmex from "../../resource/images/Bitmex.png";
import positionLong from "../../resource/images/topRankerLong.png";
import positionShort from "../../resource/images/topRankerShort.png";
import "../../css/scss/component/TopTrader.scss";
import * as utils from "../../utils";
function TopTrader(props) {
	const isLongShort = (trader) => {
		if (trader.krw < 0) {
			return <img src={positionShort} alt="position arrow" />;
		} else if (trader.krw === 0) {
			return;
		} else if (trader.krw > 0) return <img src={positionLong} alt="position arrow" />;
	};

	const RankerList = (ranker) => {
		if (ranker.krw < 0) {
			return (
				<label style={{ color: "red" }}>
					{utils.SetPriceUnitConvert(ranker.krw)}원 / {ranker.btc.toLocaleString()} BTC
				</label>
			);
		} else if (ranker.krw == 0) {
			return <label style={{ color: "white" }}>0원 / 0.00 BTC</label>;
		} else if (ranker.krw > 0) {
			return (
				<label style={{ color: "#089981" }}>
					+{utils.SetPriceUnitConvert(ranker.krw)}원 / {ranker.btc.toLocaleString()} BTC
				</label>
			);
		}
	};

	const [traders, setTraders] = useState(false);

	useEffect(() => {
		setTraders(props.traderInfo);
	}, [props.traderInfo]);
	if (traders !== false)
		return (
			<div className="fitContent">
				<div className="title-row">
					<div>
						<img src={Bitmex} />
						<span>탑 트레이더 손익현황</span>
					</div>
					<span
						className="material-symbols-outlined"
						style={{ marginleft: "100px" }}
						onClick={() => {
							props.help3(props.setShoInfoHelper);
						}}
					>
						help
					</span>
				</div>
				
				{ traders.readerBord.length > 0 &&
				
					<div className="TopRankerFixed long short">
					<span className="rankerName">{traders.readerBord[0].name}</span>
					<span>{RankerList(traders.readerBord[0])}</span>
					
					</div>
				}
				{ traders.readerBord.length > 0 &&
				<div className="TopRanker">
						{traders.newReaderBord.map((trader, i) => (
							<div key={i} className="list">
								<div className="flex-column">
									<div className="name">{trader.name}</div>
						
									<div className="krw long short">
										<div>{RankerList(trader)}</div>
									</div>
						
								</div>
						
							</div>
						))}
					</div>
				}
				
			</div>
		);
}

export default TopTrader;
