import React, { useState, useEffect, useRef } from "react";
import clearingShort from "../../resource/images/icon-clearingShort.png";
import clearingLong from "../../resource/images/icon-clearingLong.png";
import "../../css/scss/component/WhaleLiquidation.scss";

import * as utils from "../../utils";

// USD_KRW={apiData.USD_KRW}
// changeAlarm2Data={changeAlarm2Data}
// setPopup={setAlarmPopupinfo}
// isCompleteSound={isLiquidationSound}
// setIsCompleteSound={setIsLiquidationSound}
// volume={liquidationVolume}
// setVolume={setLiquidationVolume}

function WhaleLiquidation({ USD_KRW, changeAlarm2Data, setPopup, isCompleteSound, setIsCompleteSound, volume, setVolume }) {
	const volumeRef = useRef(volume);
	const isSoundRef = useRef(isCompleteSound);

	useEffect(() => {
		CallWssTrendTransaction();
	}, []);

	useEffect(() => {
		volumeRef.current = volume;
		isSoundRef.current = isCompleteSound;
	}, [volume, isCompleteSound]);

	function CallWssTrendTransaction() {
		var uri = "wss://fstream.binance.com/stream?streams=btcusdt@forceOrder"; //선물청산
		var wss = new WebSocket(uri);
		wss.onmessage = function (response) {
			var data;
			var krwPrice;
			var usdPrice;
			var type;

			data = response.data;

			if (data.indexOf("error") === -1) {
				let jData = JSON.parse(data)["data"];
				if (jData === undefined) return;

				let quantity = parseFloat(jData.o["q"]);
				usdPrice = parseFloat(jData.o["p"]) * quantity;
				krwPrice = parseFloat(usdPrice) * USD_KRW;
				type = jData.o["S"] === "BUY";

				if (krwPrice >= 30000000) {
					setTraders((current) => [{ type, name: "user", money: krwPrice, dollar: usdPrice }, ...current]);
					if (isSoundRef.current) {

						if (jData.o["S"] === "BUY") {
							const audio = new Audio('/buyClearing.mp3');
							audio.volume = volumeRef.current * 0.01;
							audio.play();
						} else {
							const audio = new Audio('/sellClearing.mp3');
							audio.volume = volumeRef.current * 0.01;
							audio.play();
						}
					}
				}
			}
		};
	}
	const [traders, setTraders] = useState([]);

	return (
		<div className="WhaleLiquidation-Contants">
			<div className="titleRow">
				<span className="title">
					실시간 고래 <span className="accent">청산</span> 알림
				</span>
				<div
					className="setting-icon"
					onClick={() =>
						setPopup({
							title: "실시간 고래 청산 알림팝업",
							type: "Alarm2",

							isCompleteSound: isCompleteSound,
							setIsCompleteSound: setIsCompleteSound,
							volume: volume,
							setVolume: setVolume,

							IsSound: (e) => {
								return isCompleteSound;
							},
							AccountValue: (e) => volume,
							okAction: (isSound, volume) => {
								changeAlarm2Data(isSound, volume);
								setIsCompleteSound(isSound);
								setVolume(volume);
								setPopup(false);
							},
							cancelAction: () => setPopup(false),
						})
					}
				>
					<span className="material-symbols-outlined">settings</span>
				</div>
			</div>
			<div className="scrollBox">
				{traders.map((trader, i) => {
					return (
						<div className="itmeBG" key={i}>
							<div className="item">
								{/* 텍스트 색상 컨트롤 스타일: long short */}
								<div className="d-flex">
									<div className={trader.type ? "traderType short" : "traderType long"}>{trader.type ? "숏" : "롱"}</div>
									<div className="innerDate">BTC</div>
								</div>
								<div className={trader.type ? "TraderMoney short" : "TraderMoney long"}>
									{utils.SetPriceUnitConvert(trader.money)}
								</div>
								<div className={trader.type ? "TraderDollar short" : "TraderDollar long"}>
									{Number(Number(trader.dollar).toFixed(0)).toLocaleString() + " $"}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
export default WhaleLiquidation;
