import { useState } from "react";
import { useEffect, useRef, React } from "react";
import "../../css/scss/component/RollingAd.scss";

export default function RollingAd(props) {
	let slideIndex = 1;

	useEffect(() => {

		showSlides(slideIndex);
		
		if (props.advertisement.length > 1 ) {

			const intervaliId = setInterval(() => {
				plusSlides(1);
			}, 5000);
	
			return () => clearInterval(intervaliId);
		}
		
	}, []);

	let showSlides = (n) => {
		var i;
		var Container = document.getElementsByClassName("AdContainer" + props.id);
		var slides = Container[0].getElementsByClassName("mySlides");

		if (n > slides.length) {
			slideIndex = 1;
		}
		if (n < 1) {
			slideIndex = slides.length;
		}

		for (i = 0; i < slides.length; i++) {
			slides[i].style.display = "none";
		}
		
		if (props.advertisement.length > 1 ) {
			slides[slideIndex - 1].style.display = "flex";
		} else {
			slides[0].style.display = "flex";
		}
	};
	// Next/previous controls
	let plusSlides = (n) => {
		showSlides((slideIndex += n));
	};

	if (props.advertisement === {}) {
		return <h1>로딩중</h1>;
	} else {
		return (
			<>
				<div className={"AdContainer" + props.id}>
				{ props.advertisement.map((item, index) => (
					<div key={index}className="mySlides">
						{props.mediaSize === "xlarge" && props.advertisement[index].pcImageUrl !== "" && (
							<a href={props.advertisement[index].pcLinkUrl} target="_blank" rel="noopener noreferrer">
								<img src={props.advertisement[index].pcImageUrl} />
							</a>
						)}
						{props.mediaSize === "xlarge" && props.advertisement[index].pcImageUrl === "" && <img src={props.advertisement[index].pcImageUrl} />}

						{props.mediaSize === "large" && props.advertisement[index].mobileLinkUrl !== "" && (
							<a href={props.advertisement[index].mobileLinkUrl} target="_blank" rel="noopener noreferrer">
							</a>
						)}
						{props.mediaSize === "large" && props.advertisement[index].mobileLinkUrl === "" && (
							<img src={props.advertisement[index].tabletImageUrl} />
						)}

						{(props.mediaSize === "medium" || (props.mediaSize === "small" && props.advertisement[index].mobileLinkUrl !== "")) && (
							<a href={props.advertisement[index].mobileLinkUrl} target="_blank" rel="noopener noreferrer">
								<img src={props.advertisement[index].mobileImageUrl} />
							</a>
						)}
						{(props.mediaSize === "medium" || (props.mediaSize === "small" && props.advertisement[index].mobileLinkUrl === "")) && (
							<img src={props.advertisement[index].mobileImageUrl} />
						)}
					</div>
				))
				}
				{ props.advertisement.length > 1 &&
					<div>
						<button type="button" className="prev" onClick={() => plusSlides(-1)}>
							<span className="material-symbols-outlined">expand_more</span>
						</button>
						<button type="button" className="next" onClick={() => plusSlides(1)}>
							<span className="material-symbols-outlined">expand_more</span>
						</button>
					</div>
				}
					
				</div>
			</>
		);
	}
}
