import React from "react";
import Message from "../Message";
import ScrollToBottom from 'react-scroll-to-bottom';

let message = "";

const Messages = ({ messages, isChatFontZoom, socketid, readmore }) => (
	<ScrollToBottom className="messages" followButtonClassName="someHiddenClass">
		<div className="innerPadding">
			<a href="#none" className="getMoreChat" onClick={readmore}>
				이전 대화 불러오기
			</a>
			{/* 상대방의 채팅과 나의 채팅 사이의 간격을 주는 클래스: .messageInterval
			아래쪽에서 위쪽으로 간격을 주는 클래스이므로 위쪽 채팅창에 넣어주어야함
			.viewMessages 와 함께 사용 */}
			{messages ? messages.map((message, i) => (
				<div className="viewMessages" key={i}>
					<Message
						message={message}
						isChatFontZoom={isChatFontZoom}
						socketid={socketid}
						previousMessageUserId={i > 0 ? messages[i - 1].message.uuid : null}
						previousMessageUserNickname={i > 0 ? messages[i - 1].message.nickname : null}
					/>
				</div>
			)): null}
		</div>
	</ScrollToBottom>
);

export default Messages;
