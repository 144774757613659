import React, { useState, useEffect } from "react";
import "../../css/scss/component/WhaleDepositAndWithdrawalHistory.scss";
import * as Api from "../../Api.js";
import * as utils from "../../utils";
function WhaleDepositAndWithdrawalHistory(props) {
	// useEffect(() => {
	// 	Api.getList(props.setTraders);
	// 	setInterval(() => {
	// 		Api.getList(props.setTraders);
	// 	}, 10000);
	// }, []);

	// let array = [];
	// array.swingLong = [];
	// array.swingShort = [];
	// array.whaleTransaction = [];
	// array.streamer = [];
	// const [traders, setTraders] = useState(array);

	return (
		<div className="whaleDepositAndWithdrawalHistory-Contants">
			<div className="titleRow">
				<span className="title">실시간 고래 입출금 내역</span>
			</div>
			<div className="scrollBox">
				{props.traders.whaleTransaction.map((trader, i) => {
					let className = trader.type === '입' ? "traderType long" : "traderType short";
					
					return (
						<div className="itmeBG" key={i}>
							<div className="item">
								
								{/* 텍스트 색상 컨트롤 스타일: long short */}
								<div className={trader.type === '입' ? "traderType long" : "traderType short"}>{trader.type}</div>
								<div className="flex-column history">
									<div>{trader.name}</div>
									<div>{trader.date}</div>
								</div>
								{/* 텍스트 색상 컨트롤 스타일: long short */}
								<div className={trader.type === '입' ? "flex-column long" : "flex-column short"}>
									<div>{utils.SetPriceUnitConvert(trader.money)}</div>
									<div>{trader.symbol}</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default WhaleDepositAndWithdrawalHistory;
