import React, { useEffect, useRef, useState } from "react";
import "../../css/scss/component/MeterChart.scss";
import * as d3 from "d3";

export default function MeterChart(props) {
	const [percent, setPercent] = useState(false);
	const [count, setCount] = useState(0);

	let el= useRef();
	const initD3 = () => {
		var Needle,
			arc,
			arcEndRad,
			arcStartRad,
			barWidth,
			chart,
			degToRad,
			el,
			endPadRad,
			height,
			i,
			needle,
			numSections,
			padRad,
			percToDeg,
			percToRad,
			percent,
			radius,
			ref,
			sectionIndx,
			sectionPerc,
			startPadRad,
			svg,
			totalPercent,
			width;

		// 바늘의 움직임(수치지정)
		percent = props.percent.data ? Number(props.percent.data[0].value) / 100 : 0;
		//percent = props.percent.data ? Number(props.percent.data[0].value) / 10 : 0;

		// 영역의 굵기
		barWidth = 10;

		// 영역의 갯수
		numSections = 7;

		// / 2 for HALF circle
		sectionPerc = 1 / numSections / 2;

		// 각 영역 사이 간격
		padRad = 0;

		// start at 270deg
		totalPercent = 0.75;

		el = d3.select(".MeterChart-gauge");
		

		width = el.node().offsetWidth;
		height = width;
		radius = Math.min(width, height) / 2;

		percToDeg = function (perc) {
			return perc * 360;
		};
		percToRad = function (perc) {
			return degToRad(percToDeg(perc));
		};
		degToRad = function (deg) {
			return (deg * Math.PI) / 180;
		};

		svg = el.append("svg").attr("width", width).attr("height", height);
		chart = svg.append("g").attr("transform", `translate(${width / 2}, ${height / 2})`);

		// build gauge bg
		for (sectionIndx = i = 1, ref = numSections; 1 <= ref ? i <= ref : i >= ref; sectionIndx = 1 <= ref ? ++i : --i) {
			arcStartRad = percToRad(totalPercent);
			arcEndRad = arcStartRad + percToRad(sectionPerc);
			totalPercent += sectionPerc;
			startPadRad = sectionIndx === 0 ? 0 : padRad / 2;
			endPadRad = sectionIndx === numSections ? 0 : padRad / 2;
			arc = d3
				.arc()
				.outerRadius(radius)
				.innerRadius(radius - barWidth)
				.startAngle(arcStartRad + startPadRad)
				.endAngle(arcEndRad - endPadRad);
			chart.append("path").attr("class", `arc chart-color${sectionIndx}`).attr("d", arc());
		}

		Needle = class Needle {
			constructor(len, radius1) {
				this.len = len;
				this.radius = radius1;
			}

			drawOn(el, perc) {
				el.append("circle").attr("class", "needle-center").attr("cx", 0).attr("cy", 0).attr("r", this.radius);
				return el.append("path").attr("class", "needle").attr("d", this.mkCmd(perc));
			}

			animateOn(el, perc) {
				var self;
				self = this;
				return el
					.transition()
					.delay(500)
					.ease(d3.easeElastic)
					.duration(2000)
					.selectAll(".needle")
					.tween("progress", function () {
						return function (percentOfPercent) {
							var progress;
							progress = percentOfPercent * perc;
							return d3.select(this).attr("d", self.mkCmd(progress));
						};
					});
			}

			mkCmd(perc) {
				var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
				thetaRad = percToRad(perc / 2); // half circle
				centerX = 0;
				centerY = 0;
				topX = centerX - this.len * Math.cos(thetaRad);
				topY = centerY - this.len * Math.sin(thetaRad);
				leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
				leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);
				rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
				rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);
				return `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`;
			}
		};
		// 바늘 사이즈
		needle = new Needle(40, 10);
		needle.drawOn(chart, 0);
		needle.animateOn(chart, percent);
		setCount(parseInt((parseFloat(percent) * 100).toString()));
	};

	useEffect(() => {
		setPercent(props.percent);
		if (percent === false)
			 return;

		if(el ===undefined && el === null) {
			return;
		}
		else{
			try{
				if(el.current.children.length ===3){
					initD3();
				}
			}
			catch{
			}
		}

		
	}, [props.percent]);

	const getChart=(el)=>{
		
	}
	return (
		<div ref={el} className="MeterChart-gauge">
			{/* MeterChart의 바늘이 가지는 값이 노출되어야함 */}
			
			<div className="MeterChartVal">{count}</div>
			<div className="MeterChartText left">공포</div>
			<div className="MeterChartText right">탐욕</div>
		</div>
	);
}
