import { useEffect } from "react";
import logoM_drk from "../../resource/images/logoM_drk.png";
import logoM_lgt from "../../resource/images/logoM_lgt.png";
import logoTPC_drk from "../../resource/images/logoTPC_drk.png";
import logoTPC_lgt from "../../resource/images/logoTPC_lgt.png";
import "../../css/scss/component/logo.scss";

function Logo(props) {
	useEffect(() => {
		document.body.className=(props.isDarkMode ? ['bodyBG_drk'] : ['bodyBG_lgt']) ;
	}, [props.isDarkMode])
	return (
		<div className="interval">
				{props.isDarkMode ? <img className="logoM" src={logoM_drk} alt="logo M" />
				:
				<img className="logoM" src={logoM_lgt} alt="logo M" />}
			
				{props.isDarkMode ? <img className="logoTPC" src={logoTPC_drk} alt="logo tablet pc" /> :
				<img className="logoTPC" src={logoTPC_lgt} alt="logo tablet pc" />}
		</div>
	);
}

export default Logo;
