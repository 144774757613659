import { React, useRef, useState, useEffect } from "react";
import "./css/scss/index.scss";

import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import Popup from "./popup/index";
import Alarm from "./popup/Alarm";
import Help from "./popup/Help";
import ResistanceZone from "./component/ResistanceZone";
import { GlobalProvider } from "./context";
import AdvancedChart from "./widget/AdvancedChart";
import CoinAnalysis from "./widget/CoinAnalysis";
import TickerTape from "./widget/TickerTape";
import binance from "./resource/images/Binance.png";
import lgtIcon from "./resource/images/lgtIconButton.png";
import DominanceChartEth from "./widget/DominanceChartEth";
import DominanceChartBtc from "./widget/DominanceChartBtc";
import DominanceChartUsdt from "./widget/DominanceChartUsdt";
import ExchangeRateChart from "./component/ExchangeRateChart";
import { setNickName } from "./bitcoin24Chat/Join/JoinAction";
import { setMyNiceName } from "./bitcoin24Chat/Join/JoinProperty";
import snsIcon1 from "./resource/images/icon_naverCafe.png";
import snsIcon2 from "./resource/images/icon_youtube.png";
import snsIcon3 from "./resource/images/icon_instagram.png";
import snsIcon4 from "./resource/images/icon_kakao.png";
import snsIcon5 from "./resource/images/icon_emali.png";
import snsIcon6 from "./resource/images/icon_calendar.png";
import * as action from "./bitcoin24Chat/Join/JoinAction";

import MeterChart from "./component/MeterChart/index";
import LongShortRate from "./component/LongShortRate";
import Logo from "./component/Logo";
import Join from "./bitcoin24Chat/Join";
import TopTrader from "./component/TopTrader";
import WhaleConclusion from "./component/WhaleConclusion";
import WhaleLiquidation from "./component/WhaleLiquidation";
import WhaleDepositAndWithdrawalHistory from "./component/WhaleDepositAndWithdrawalHistory";
import CoinDropDownInfo from "./component/CoinDropDownInfo";
import CoinList from "./widget/CoinList";
import TopCoinList from "./widget/TopCoinList";
import * as Api from "./Api.js";
import RollingAd from "./component/RollingAd";
import ReactDOM from "react-dom";
import FundingRate from "./component/FundingRate";
const PUBLISHKEY = process.env.REACT_APP_PUBNUB_PUBLSISH_KEY;
const SUBSCRIBEKEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

//btc
let btcPrice = 0;
export const getTopSendKimpBtc = () => {
	return btcPrice;
};
export const setTopSendKimpBtc = (value) => {
	btcPrice = value;
};
//eth
let ethPrice = 0;
export const getTopSendKimpEth = () => {
	return ethPrice;
};
export const setTopSendKimpEth = (value) => {
	ethPrice = value;
};

//Xrp
let xrpPrice = 0;
export const getTopSendKimpXrp = () => {
	return xrpPrice;
};
export const setTopSendKimpXrp = (value) => {
	xrpPrice = value;
};
//Eos
let eosPrice = 0;
export const getTopSendKimpEos = () => {
	return eosPrice;
};
export const setTopSendKimpEos = (value) => {
	eosPrice = value;
};

//btc
let btcPriceB = 0;
export const getBTopSendKimpBtc = () => {
	return btcPriceB;
};
export const setBTopSendKimpBtc = (value) => {
	btcPriceB = value;
};
//eth
let ethPriceB = 0;
export const getBTopSendKimpEth = () => {
	return ethPriceB;
};
export const setBTopSendKimpEth = (value) => {
	ethPriceB = value;
};

//Xrp
let xrpPriceB = 0;
export const getBTopSendKimpXrp = () => {
	return xrpPriceB;
};
export const setBTopSendKimpXrp = (value) => {
	xrpPriceB = value;
};
//Eos
let eosPriceB = 0;
export const getBTopSendKimpEos = () => {
	return eosPriceB;
};
export const setBTopSendKimpEos = (value) => {
	eosPriceB = value;
};

const useCurrentMediaSize = () => {
	const [mediaSize, setMediaSize] = useState("small");
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < breakpoints.small) {
				setMediaSize("small");
			} else if (window.innerWidth < breakpoints.medium) {
				setMediaSize("medium");
			} else if (window.innerWidth < breakpoints.large) {
				setMediaSize("large");
			} else if (window.innerWidth < breakpoints.xlarge) {
				if (navigator.userAgent.toLowerCase().indexOf("mobile") != -1) {
					setMediaSize("large");
				} else {
					setMediaSize("xlarge");
				}
			} else {
				setMediaSize("xlarge");
			}
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return mediaSize;
};

const breakpoints = {
	small: 576,
	medium: 768,
	large: 992,
	xlarge: 1200,
};

var text1 = `BTCUSDT.P, ETHUSDT.P 바이낸스 차트 기준 다수 전업 트레이더들의 트레이딩 구간을 종합하여 나타낸 가격입니다.\r\n\r\n※ 트레이딩 스타일에 따라 손실 및 이익의 결과가 달라질\r\n　수 있으므로 참고용으로만 이용해 주시고,\r\n　업데이트 시간은 20시30분이며 시장 변동성에 따라\r\n　업데이트 시간이 변경될 수 있습니다.`;
var text2 = `공식 운영시간\r\n"20:30 - 02:30"\r\n\r\n미장서머타임 적용시\r\n"21:30 - 03:00"\r\n\r\n※ 시장변동성에 따라 운영시간이 변경될 수 있으며, 스트리머들의 포지션 변경에 따라 딜레이가 발생할 수 있습니다.`;
var text3 = `※ 비트맥스에서 제공하는 업데이트 시간은 한국시간 기준 오후 9시 (12:00 UTC)인 점 안내드립니다.\r\n\r\n
※ 비트코인24는 트레이더의 예측 포지션 등 정확하지 않은 정보는 제공하지 않습니다.`;



function App() {
	const mediaSize = useCurrentMediaSize();
	const [isMinimize, setIsMinimize] = useState("none");
	const [isDarkMode, setIsDarkMode] = useState(
		JSON.parse(localStorage.getItem("isDarkMod")) === null ? true : JSON.parse(localStorage.getItem("isDarkMod"))
	);
	const [meterChartValue, setMeterChartValue] = useState({});
	const [apiData, setApiData] = useState(false);
	const [lists, setLists] = useState(false); // 롱,숏, 고래 입출금 내역
	const [apiList, setApiList] = useState(false);
	const [walletState, setWalletState] = useState(false);
	const [showInfoHelper, setShoInfoHelper] = useState(false);
	const [showChatRoom, setShowChatRoom] = useState(true);
	const [Popupinfo, setPopupinfo] = useState(false);
	const [alarmPopupinfo, setAlarmPopupinfo] = useState(false);
	const [helpPopupinfo, setHelpPopupinfo] = useState(false);
	const [LongShort, setLongShort] = useState(false);
	const [TopLongShort, setTopLongShort] = useState(false);
	const [topLongShortDrop, setTopLongShortDrop] = useState("5m");
	const [longShortDrop, setLongShortDrop] = useState("5m");

	let topLongShortDropRef = useRef(topLongShortDrop);
	let longShortDropRef = useRef(longShortDrop);

	let dataArray = ["5m", "5m", "15m", "30m", "1h", "4h", "12h", "1d"];
	const [chattingStyle, setChattingStyle] = useState("chatting-roomCover");

	// 알람 설정
	const [isConclusionSound, setIsConclusionSound] = useState(false); // 체결 알람 소리 여부 WhaleConclusion
	const [conclusionVolume, setConclusionVolume] = useState(50); // 체결 볼륨 크기 WhaleConclusion
	const [conclusionPrice, setConclusionPrice] = useState(5); // 체결 기준가 (억) WhaleConclusion
	const [isLiquidationSound, setIsLiquidationSound] = useState(false); // 청산 알람 소리 여부 WhaleLiquidation
	const [liquidationVolume, setLiquidationVolume] = useState(50); // 청산 볼륨 크기 WhaleLiquidation

	let array = [];
	array.swingLong = [];
	array.swingShort = [];
	array.whaleTransaction = [];
	array.streamer = [];

	const changeAlarm1Data = (isConclusionSound, conclusionVolume, conclusionPrice) => {
		setIsConclusionSound(isConclusionSound);
		setConclusionVolume(conclusionVolume);
		setConclusionPrice(conclusionPrice);
	};

	const changeAlarm2Data = (isLiquidationSound, liquidationVolume) => {
		setIsLiquidationSound(isLiquidationSound);
		setLiquidationVolume(liquidationVolume);
	};

	useEffect(() => {
		localStorage.setItem("isDarkMod", JSON.stringify(isDarkMode));
	}, [isDarkMode]);

	useEffect(() => {}, [mediaSize, Popupinfo]);

	useEffect(() => {
		Api.getList(setLists);
		setInterval(() => {
			Api.getList(setLists);
		}, 60000);
	}, []);

	useEffect(() => {
		setInterval(() => {
			Api.getLongShort(setLongShort, longShortDropRef.current);
			Api.getTopLongShort(setTopLongShort, topLongShortDropRef.current);
		}, 60000);
	}, []);

	useEffect(() => {
		topLongShortDropRef.current = topLongShortDrop;
		longShortDropRef.current = longShortDrop;
		Api.getLongShort(setLongShort, longShortDropRef.current);
		Api.getTopLongShort(setTopLongShort, topLongShortDropRef.current);
	}, [topLongShortDrop, longShortDrop]);

	useEffect(() => {
		ReactDOM.unstable_batchedUpdates(() => {
			Api.getBitcoin(setApiData);
			Api.getUpbitApi(setApiList);
			Api.getFearAndGreed(setMeterChartValue);
			Api.getWallet_state(setWalletState);
			Api.getLongShort(setLongShort, longShortDropRef.current);
			Api.getTopLongShort(setTopLongShort, topLongShortDropRef.current);
		});
	}, []);

	const help1 = (setHelpPopupinfo) => {
		setHelpPopupinfo({
			title: "도움말",
			helpText: text1,
			okAction: () => {
				setHelpPopupinfo(false);
			},
		});
	};

	const help2 = (setHelpPopupinfo) => {
		setHelpPopupinfo({
			title: "도움말",
			helpText: text2,
			okAction: () => {
				setHelpPopupinfo(false);
			},
		});
	};

	const help3 = () => {
		setHelpPopupinfo({
			title: "도움말",
			helpText: text3,
			okAction: () => {
				setHelpPopupinfo(false);
			},
		});
	};

	const setNickname = (setPopupinfo) => {
		setPopupinfo({
			title: "닉네임 변경",
			type: "NickName",
			okAction: (e) => {
				if (e === undefined) {
					// setNickName("NickName");
					// setMyNiceName("NickName");
					alert("변경할 닉네임을 입력해주세요.");
				} else {
					if (e.trim() === "") {
						alert("변경할 닉네임을 입력해주세요.");
					} else {
						setNickName(e);
						setMyNiceName(e);
						setPopupinfo(false);
					}
				}
			},
			cancelAction: () => {
				setPopupinfo(false);
			},
		});
	};

	const setSound = (sound, setSound) => {
		setSound(sound);
	};

	//채팅
	if(!localStorage.getItem("uuid")) {action.initializeAction()}
	const pubnub = new PubNub({
		publishKey: PUBLISHKEY,
		subscribeKey: SUBSCRIBEKEY,
		userId: localStorage.getItem("uuid"),
	});
	return (
		<GlobalProvider>
			<div className="App">
				{Popupinfo === false ? <></> : <Popup {...Popupinfo}></Popup>}
				{alarmPopupinfo === false ? <></> : <Alarm {...alarmPopupinfo}></Alarm>}
				{helpPopupinfo === false ? <></> : <Help {...helpPopupinfo}></Help>}
				<div id="index">
					<img style={{ display: isMinimize, position: "fixed", zIndex: 100, bottom: 100, right: 300 }} src={lgtIcon} />
					<header className={isDarkMode ? "headerBG_drk" : "headerBG_lgt"}>
						<Logo isDarkMode={isDarkMode} />
						<div className="theme">
							<span className="material-symbols-outlined" onClick={() => setIsDarkMode(!isDarkMode)}>
								{isDarkMode ? "dark_mode" : "light_mode"}
							</span>
						</div>
						<div className="sns-box">
							<div className="flexVC">
								<a href={`https://cafe.naver.com/hsbtccokr`} target="_blank">
									<div>
										<img src={snsIcon1} alt="naverCafe" />
										<span>블로그</span>
									</div>
								</a>
								<a href={`https://www.youtube.com/@bitcoin24_korea`} target="_blank">
									<div>
										<img src={snsIcon2} alt="youtube" />
										<span>유튜브</span>
									</div>
								</a>
								<a href={`https://instagram.com/bitcoin24.co.kr?igshid=YmMyMTA2M2Y=`} target="_blank">
									<div>
										<img src={snsIcon3} alt="instagram" />
										<span>인스타</span>
									</div>
								</a>
							</div>
							<div className="flexVC">
								<a href={`https://open.kakao.com/o/sHryeN5e`} target="_blank">
									<div>
										<img src={snsIcon4} alt="kakao" />
										<span>공부방 문의</span>
									</div>
								</a>
								<a href={`mailto:bitcoin24hs@naver.com`} target="_blank">
									<div>
										<img src={snsIcon5} alt="emali" />
										<span>광고 문의</span>
									</div>
								</a>
								<a href={`https://kr.investing.com/economic-calendar/`} target="_blank">
									<div>
										<img src={snsIcon6} alt="calendar" />
										<span>경제 캘린더</span>
									</div>
								</a>
							</div>
						</div>
						{apiData !== false && apiData.advertisement.A.pcImageUrl !== "" && (
							<div className="advertisement-A">
								{apiData !== false && mediaSize === "xlarge" && apiData.advertisement.A.pcLinkUrl !== "" && (
									<a href={apiData.advertisement.A.pcLinkUrl} target="_blank" rel="noopener noreferrer">
										<img src={apiData.advertisement.A.pcImageUrl} />
									</a>
								)}
								{apiData !== false && mediaSize === "xlarge" && apiData.advertisement.A.pcLinkUrl === "" && (
									<img src={apiData.advertisement.A.pcImageUrl} />
								)}

								{apiData !== false && mediaSize === "large" && apiData.advertisement.A.mobileLinkUrl !== "" && (
									<a href={apiData.advertisement.A.mobileLinkUrl} target="_blank" rel="noopener noreferrer">
										<img src={apiData.advertisement.A.tabletImageUrl} />
									</a>
								)}
								{apiData !== false && mediaSize === "large" && apiData.advertisement.A.mobileLinkUrl === "" && (
									<img src={apiData.advertisement.A.tabletImageUrl} />
								)}

								{apiData !== false &&
									(mediaSize === "medium" || mediaSize === "small") &&
									apiData.advertisement.A.mobileLinkUrl !== "" && (
										<a href={apiData.advertisement.A.mobileLinkUrl} target="_blank" rel="noopener noreferrer">
											<img src={apiData.advertisement.A.mobileImageUrl} />
										</a>
									)}
								{apiData !== false &&
									(mediaSize === "medium" || mediaSize === "small") &&
									apiData.advertisement.A.mobileLinkUrl === "" && <img src={apiData.advertisement.A.mobileImageUrl} />}
							</div>
						)}
					</header>

					{apiData !== false && mediaSize !== "xlarge" && (
						<aside className={isDarkMode ? "aside_chatM theme_drk" : "aside_chatM theme_lgt"}>
							<button
								type="button"
								className={!showChatRoom ? "aside_chatMBtn" : "aside_chatMBtn hide"}
								onClick={() => setShowChatRoom(true)}
							>
								<span className="material-symbols-outlined">question_answer</span>
							</button>
							<div className={chattingStyle}>
								<div className="chatting-room">
									{apiData !== false && mediaSize === "large" && apiData.advertisement.C.length > 0 && (
										<div className={showChatRoom ? "advertisement-C" : "advertisement-C hide"}>
											{apiData !== false ? (
												<RollingAd id={1} mediaSize={mediaSize} advertisement={apiData.advertisement.C} />
											) : (
												<></>
											)}
										</div>
									)}
									<PubNubProvider client={pubnub}>
										<Join
											data={lists}
											mediaSize={mediaSize}
											showChatRoom={showChatRoom}
											setShowChatRoom={setShowChatRoom}
											setNickname={setNickname}
											setPopupinfo={setPopupinfo}
											setShoInfoHelper={setHelpPopupinfo}
											showInfoHelper={showInfoHelper}
											v
											help2={help2}
											setChattingStyle={setChattingStyle}
										/>
									</PubNubProvider>
								</div>
							</div>
						</aside>
					)}

					<section>
						<div className="max-container">
							{apiData !== false && mediaSize === "xlarge" && (
								<aside className={isDarkMode ? "aside_chatPC theme_drk" : "aside_chatPC theme_lgt"}>
									{apiData !== false && apiData.advertisement.C.length > 0 && (
										<div className="advertisement-C">
											{apiData !== false ? (
												<RollingAd id={2} mediaSize={mediaSize} advertisement={apiData.advertisement.C} />
											) : (
												<></>
											)}
										</div>
									)}
									<PubNubProvider client={pubnub}>

									<Join
										api={apiData}
										data={lists}
										mediaSize={mediaSize}
										showChatRoom={showChatRoom}
										setShowChatRoom={setShowChatRoom}
										setNickname={setNickname}
										setPopupinfo={setPopupinfo}
										setShoInfoHelper={setHelpPopupinfo}
										showInfoHelper={showInfoHelper}
										help2={help2}
										setChattingStyle={setChattingStyle}
									/>
									</PubNubProvider>

								</aside>
							)}
							<div className="section_top">
								<div className="cover-grid">
									<div className="container container-1">
										<TopCoinList isDarkMode={isDarkMode} mediaSize={mediaSize} />
									</div>
									<div className={isDarkMode ? "container container-2 theme_drk" : "container container-2 theme_lgt"}>
										{apiData !== false && apiData.advertisement.B.pcImageUrl !== "" ? (
											<article className="advertisement-B">
												{apiData !== false && mediaSize === "xlarge" && apiData.advertisement.B.pcLinkUrl !== "" && (
													<a href={apiData.advertisement.B.pcLinkUrl} target="_blank" rel="noopener noreferrer">
														<img src={apiData.advertisement.B.pcImageUrl} />
													</a>
												)}
												{apiData !== false && mediaSize === "xlarge" && apiData.advertisement.B.pcLinkUrl === "" && (
													<img src={apiData.advertisement.B.pcImageUrl} />
												)}
												{apiData !== false && mediaSize === "large" && apiData.advertisement.B.mobileLinkUrl !== "" && (
													<a href={apiData.advertisement.B.mobileLinkUrl} target="_blank" rel="noopener noreferrer">
														<img src={apiData.advertisement.B.tabletImageUrl} />
													</a>
												)}
												{apiData !== false && mediaSize === "large" && apiData.advertisement.B.mobileLinkUrl === "" && (
													<img src={apiData.advertisement.B.tabletImageUrl} />
												)}
												{apiData !== false &&
													(mediaSize === "medium" || mediaSize === "small") &&
													apiData.advertisement.B.mobileLinkUrl !== "" && (
														<a href={apiData.advertisement.B.mobileLinkUrl} target="_blank" rel="noopener noreferrer">
															<img src={apiData.advertisement.B.mobileImageUrl} />
														</a>
													)}
												{apiData !== false &&
													(mediaSize === "medium" || mediaSize === "small") &&
													apiData.advertisement.B.mobileLinkUrl === "" && (
														<img src={apiData.advertisement.B.mobileImageUrl} />
													)}
											</article>
										) : (
											<div>
												{mediaSize === "xlarge" ? <article className="advertisement-B"></article> : <article></article>}
											</div>
										)}
										<div className="d-flex">
											<article className="article-2">
												{TopLongShort !== false && (
													<div className="g-common-box LongShortRate-box">
														<LongShortRate
															dataArray={dataArray}
															mark={binance}
															LongShort={LongShort}
															TopLongShort={TopLongShort}
															topLongShortDrop={topLongShortDrop}
															setTopLongShortDrop={setTopLongShortDrop}
															longShortDrop={longShortDrop}
															setLongShortDrop={setLongShortDrop}
														/>
													</div>
												)}
												<div className="division-middle">
													<div className="division-column">
														<div className="g-common-box MeterChart-box">
															<div className="common-titleCenter">
																<span className="common-title">공포/탐욕 지수</span>
															</div>

															<MeterChart percent={meterChartValue} />
														</div>
														<FundingRate />
													</div>
													<div className="g-common-box ResistanceZone-box">
														<span
															className="material-symbols-outlined"
															onClick={() => {
																help1(setHelpPopupinfo);
															}}
														>
															help
														</span>
														<ResistanceZone data={lists} isDarkMode={isDarkMode} />
													</div>
												</div>
											</article>
											<article className="article-3">
												<div className="g-common-box TopTrader-box">
													{lists !== false && (
														<TopTrader traderInfo={lists} setShoInfoHelper={setHelpPopupinfo} help3={help3} />
													)}
												</div>
												<div className="division-column">
													<div className="g-common-box CoinAnalysis-box">
														<CoinAnalysis isDarkMode={isDarkMode} />
													</div>
													{apiData !== false && apiData.advertisement.D.pcImageUrl !== "" ? (
														<div className="advertisement-D">
															{apiData !== false &&
																mediaSize === "xlarge" &&
																apiData.advertisement.D.pcLinkUrl !== "" && (
																	<a
																		href={apiData.advertisement.D.pcLinkUrl}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<img src={apiData.advertisement.D.pcImageUrl} />
																	</a>
																)}
															{apiData !== false &&
																mediaSize === "xlarge" &&
																apiData.advertisement.D.pcLinkUrl === "" && (
																	<img src={apiData.advertisement.D.pcImageUrl} />
																)}
															{apiData !== false &&
																mediaSize === "large" &&
																apiData.advertisement.D.mobileLinkUrl !== "" && (
																	<a
																		href={apiData.advertisement.D.mobileLinkUrl}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<img src={apiData.advertisement.D.tabletImageUrl} />
																	</a>
																)}
															{apiData !== false &&
																mediaSize === "large" &&
																apiData.advertisement.D.mobileLinkUrl === "" && (
																	<img src={apiData.advertisement.D.tabletImageUrl} />
																)}
															{apiData !== false &&
																(mediaSize === "medium" || mediaSize === "small") &&
																apiData.advertisement.D.mobileLinkUrl !== "" && (
																	<a
																		href={apiData.advertisement.D.mobileLinkUrl}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<img src={apiData.advertisement.D.mobileImageUrl} />
																	</a>
																)}
															{apiData !== false &&
																(mediaSize === "medium" || mediaSize === "small") &&
																apiData.advertisement.D.mobileLinkUrl === "" && (
																	<img src={apiData.advertisement.D.mobileImageUrl} />
																)}
														</div>
													) : (
														<div>{mediaSize === "xlarge" ? <div className="advertisement-D" /> : <div />}</div>
													)}
												</div>
											</article>
										</div>
									</div>
									<div className={isDarkMode ? "container container-3 theme_drk" : "container container-3 theme_lgt"}>
										{apiData !== false && lists !== false ? (
											<article className="article-1">
												<div className="g-common-box WhaleConclusion-box">
													<WhaleConclusion
														USD_KRW={apiData.USD_KRW}
														changeAlarm1Data={changeAlarm1Data}
														setPopup={setAlarmPopupinfo}
														isCompleteSound={isConclusionSound}
														setIsCompleteSound={setIsConclusionSound}
														volume={conclusionVolume}
														setVolume={setConclusionVolume}
														standardPrice={conclusionPrice}
														setStandardPrice={setConclusionPrice}
													/>
												</div>
												<div className="g-common-box WhaleLiquidation-box">
													<WhaleLiquidation
														USD_KRW={apiData.USD_KRW}
														changeAlarm2Data={changeAlarm2Data}
														setPopup={setAlarmPopupinfo}
														isCompleteSound={isLiquidationSound}
														setIsCompleteSound={setIsLiquidationSound}
														volume={liquidationVolume}
														setVolume={setLiquidationVolume}
													/>
												</div>
												<div className="g-common-box WhaleDepositAndWithdrawalHistory-box">
													<WhaleDepositAndWithdrawalHistory traders={lists} USD_KRW={apiData.USD_KRW} />
												</div>
											</article>
										) : (
											<></>
										)}
									</div>
								</div>
								<article className={isDarkMode ? "TickerTape-box theme_drk" : "TickerTape-box theme_lgt"}>
									<TickerTape isDarkMode={isDarkMode} />
								</article>
							</div>
							<div className={isDarkMode ? "section_bottom theme_drk" : "section_bottom theme_lgt"}>
								<article className="article-1">
									<div className="g-common-box">
										<div className="title">BTC 도미넌스</div>
										<div className="chart-cover">
											<DominanceChartBtc isDarkMode={isDarkMode} />
										</div>
									</div>
									<div className="g-common-box">
										<div className="title">ETH 도미넌스</div>
										<div className="chart-cover">
											<DominanceChartEth isDarkMode={isDarkMode} />
										</div>
									</div>
									<div className="g-common-box">
										<div className="title">USDT 도미넌스</div>
										<div className="chart-cover">
											<DominanceChartUsdt isDarkMode={isDarkMode} />
										</div>
									</div>
								</article>
								<article className="article-2">
									<div className="g-common-box">
										<CoinList isDarkMode={isDarkMode} mediaSize={mediaSize} />
									</div>

									{apiData !== false && apiData.advertisement.E.pcImageUrl !== "" && (
										<div className="advertisement-E sticky-P">
											{apiData !== false && mediaSize === "xlarge" && apiData.advertisement.E.pcLinkUrl !== "" && (
												<a href={apiData.advertisement.E.pcLinkUrl} target="_blank" rel="noopener noreferrer">
													<img src={apiData.advertisement.E.pcImageUrl} />
												</a>
											)}
											{apiData !== false && mediaSize === "xlarge" && apiData.advertisement.E.pcLinkUrl === "" && (
												<img src={apiData.advertisement.E.pcImageUrl} />
											)}

											{apiData !== false && mediaSize === "large" && apiData.advertisement.E.mobileLinkUrl !== "" && (
												<a href={apiData.advertisement.E.mobileLinkUrl} target="_blank" rel="noopener noreferrer">
													<img src={apiData.advertisement.E.tabletImageUrl} />
												</a>
											)}
											{apiData !== false && mediaSize === "large" && apiData.advertisement.E.mobileLinkUrl === "" && (
												<img src={apiData.advertisement.E.tabletImageUrl} />
											)}

											{apiData !== false &&
												(mediaSize === "medium" || mediaSize === "small") &&
												apiData.advertisement.E.mobileLinkUrl !== "" && (
													<a href={apiData.advertisement.E.mobileLinkUrl} target="_blank" rel="noopener noreferrer">
														<img src={apiData.advertisement.E.mobileImageUrl} />
													</a>
												)}
											{apiData !== false &&
												(mediaSize === "medium" || mediaSize === "small") &&
												apiData.advertisement.E.mobileLinkUrl === "" && <img src={apiData.advertisement.E.mobileImageUrl} />}
										</div>
									)}

									{apiData !== false && apiData.advertisement.C[0].pcImageUrl !== "" && (
										<div>
											{apiData !== false && mediaSize === "large" && (
												<div className={!showChatRoom ? "advertisement-C" : "advertisement-C hide"}>
													<RollingAd id={3} mediaSize={mediaSize} advertisement={apiData.advertisement.C} />
												</div>
											)}
											{apiData !== false && (mediaSize === "medium" || mediaSize === "small") && (
												<div className="mobileAdvertisement-C">
													<RollingAd id={4} mediaSize={mediaSize} advertisement={apiData.advertisement.C} />
												</div>
											)}
										</div>
									)}
								</article>
							</div>
						</div>
					</section>

					<footer className={isDarkMode ? "footer_drk" : "footer_lgt"}>
						<p>
							비트코인24 에서 제공하는 모든 가격정보 및 투자 관련 정보는 단순 참고용이므로 절대 메인 지표로 사용하지 마시고,
							<br />
							투자의 책임은 전적으로 본인에게 있으며 비트코인24는 어떠한 책임도 부담하지 않습니다.
						</p>
					</footer>
				</div>
			</div>
		</GlobalProvider>
	);
}

export default App;
