import React, { useEffect, useState } from "react";
import PercentBar from "../PercentBar";
import "../../css/scss/component/LongShortRate.scss";

import FlatDropDown from "../FlatDropDown";
import DropDownBox from "../DropDownBox";

function LongShortRate(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [longAccount, setLongAccount] = useState(0);
	const [topLongAccount, setTopLongAccount] = useState(0);
	const [shortAccount, setShortAccount] = useState(0);
	const [topShortAccount, setTopShortAccount] = useState(0);
	
	
	useEffect(() => {
		if (props.LongShort !== false) {
			setLongAccount(Number(props.LongShort[0].longAccount * 100).toFixed(1));
			setShortAccount(Number(props.LongShort[0].shortAccount * 100).toFixed(1));
			setIsLoading(true);
		}

		if (props.TopLongShort !== false) {
			setTopLongAccount(Number(props.TopLongShort[0].longAccount * 100).toFixed(1));
			setTopShortAccount(Number(props.TopLongShort[0].shortAccount * 100).toFixed(1));
			setIsLoading(true);
		}
	}, [props.LongShort, props, props.TopLongShort]);


	if (isLoading === true)
		return (
			<div className="LongShortRate-Contants">
				<div className="title-row">
					<div className="flexVC">
						<img src={props.mark} />
						<span>롱/숏 비율</span>
					</div>
					{/* 셀렉트 박스 */}
					<div className="selectBox">
						<FlatDropDown ObjectArray={props.dataArray} onChange={(e) => props.setLongShortDrop(e)} />
					</div>
				</div>
				<div className="flexVC_b">
					<div className="inner-box long">
						<div className="innerBox-title">
							LONG(공매수)비율 : <span>{longAccount}%</span>
						</div>
						<PercentBar percent={longAccount} color="#089981" />
					</div>
					<div className="inner-box short">
						<div className="innerBox-title">
							SHORT(공매도)비율 : <span>{shortAccount}%</span>
						</div>
						<PercentBar percent={shortAccount} color="#ff313d" />
					</div>
					<div>{props.value}</div>
				</div>
				<div className="title-row">
					<div className="flexVC">
						<img src={props.mark} />
						<span>탑 트레이더 롱/숏비율</span>
					</div>
					<div className="selectBox">
						<FlatDropDown ObjectArray={props.dataArray} onChange={(e) => props.setTopLongShortDrop(e)} />
					</div>
				</div>
				<div className="flexVC_b">
					<div className="inner-box long">
						<div className="innerBox-title">
							LONG(공매수)비율 : <span>{topLongAccount}%</span>
						</div>
						<PercentBar percent={topLongAccount} color="#089981" />
					</div>
					<div className="inner-box short">
						<div className="innerBox-title">
							SHORT(공매도)비율 : <span>{topShortAccount}%</span>
						</div>
						<PercentBar percent={topShortAccount} color="#ff313d" />
					</div>
					<div>{props.value}</div>
				</div>
			</div>
		);
}

export default LongShortRate;
