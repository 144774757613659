import React, { useEffect, useState } from "react";
import binence from "../../resource/images/Binance.png";
import ReactDOM from 'react-dom';
import * as Api from "../../Api.js";


export default function FundingRate() {
    const [fundingRate, setFundingRate] = useState("");
    const [currentTimer,setCurrentTimer] = useState("");
    const [isReset , setIsReset] = useState(false);
    const urls = {
        BTC: 'https://fapi.binance.com/fapi/v1/premiumIndex?symbol=BTCUSDT',
        ETH: 'https://fapi.binance.com/fapi/v1/premiumIndex?symbol=ETHUSDT',
        XRP: 'https://fapi.binance.com/fapi/v1/premiumIndex?symbol=XRPUSDT',
      };

        const promise = async()=>{
            const result = {};
            await Promise.all([
                Api.getFundingRate(urls.BTC, result,'BTC'),
                Api.getFundingRate(urls.ETH, result,'ETH'),
                Api.getFundingRate(urls.XRP, result, 'XRP')])
                // setFundingRate(result);
                return result;
        }

    function msToTime(duration) {
        var milliseconds = parseInt((duration%1000)/100)
            , seconds = parseInt((duration/1000)%60)
            , minutes = parseInt((duration/(1000*60))%60)
            , hours = parseInt((duration/(1000*60*60))%24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        //return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
        return hours + ":" + minutes + ":" + seconds;
    }

  
const getTime = (res) => {
    let fundingStamp = res.BTC.nextFundingTime;
    let fundingdate = new Date(fundingStamp); // 타임스탬프를 Date 객체로 변환
    //fundingdate.setTime(fundingStamp + (9 * 60 * 60 * 1000)); // GMT 제거
    const CurrentTime = new Date();

    //let 펀딩시간 ='펀딩 시간:' + fundingdate.getFullYear() + "년" +fundingdate.getMonth() +"월" +fundingdate.getDate() + "일" + fundingdate.getHours() + "시" +fundingdate.getMinutes() + "분" +fundingdate.getSeconds() + "초";
    //let 현재시간 ='현재 시간:' + CurrentTime.getFullYear() + "년" +CurrentTime.getMonth() +"월" +CurrentTime.getDate() + "일" + CurrentTime.getHours() + "시" +CurrentTime.getMinutes() + "분" +CurrentTime.getSeconds() + "초";


    let countDownTimeStamp = fundingdate.getTime() - CurrentTime.getTime();
    let CountDown = msToTime(countDownTimeStamp);
    setFundingRate(res);
    return CountDown;
}

    //비율 계산
    // useEffect(() => {
        
    //   ReactDOM.unstable_batchedUpdates(() => {
      
    // });
    // },[fundingRate])
    useEffect(()=>{
        promise().then((res)=>{
            let timer = setInterval(() => {
                let currectTime = getTime(res);
                setCurrentTimer(currectTime);
                if (currectTime ==='00:00:00') {
                    clearInterval(timer);
                    setIsReset(!isReset);
                }
            }, 1000);
        }); 
    },[isReset])

    return(
    <div className="g-common-box ratio-box">
        <div className="flexMC">
            <img src = {binence}/>
            <span className="common-title">펀딩비율</span>
        </div>
        <div className="countDown">{currentTimer} </div>
        <div className="list-box">
            <div className="list">
                <span className="title">비트코인</span>
                    <span className={fundingRate ===''? '' : (Number(fundingRate.BTC.lastFundingRate) < 0 ? 'short' : 'long')}>
                        {fundingRate ===''? '' : (Number(fundingRate.BTC.lastFundingRate) > 0 ? '+':'') + Number(fundingRate.BTC.lastFundingRate * 100).toFixed(4)}%
                    </span>
            </div>
            <div className="list">
                <span className="title">이더리움</span>
                <span className={fundingRate ===''? '' : (Number(fundingRate.ETH.lastFundingRate) < 0 ? 'short' : 'long')}>
                    {fundingRate ===''? '' : (Number(fundingRate.ETH.lastFundingRate) > 0 ? '+':'') + Number(fundingRate.ETH.lastFundingRate * 100).toFixed(4)}%
                </span>
            </div>
            <div className="list">
                <span className="title">리플</span>
                <span className={fundingRate ===''? '' : (Number(fundingRate.XRP.lastFundingRate) < 0 ? 'short' : 'long')}>
                    {fundingRate ===''? '' : (Number(fundingRate.XRP.lastFundingRate) > 0 ? '+':'') + Number(fundingRate.XRP.lastFundingRate * 100).toFixed(4)}%
                </span>
            </div>
        </div>
    </div>
    )
}