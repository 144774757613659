import React, { useState, useEffect } from "react";
import InfoBar from "../InfoBar";
import Messages from "../Messages";
import Input from "../Input";
import * as action from "./JoinAction";
import * as property from "./JoinProperty";
import { usePubNub } from 'pubnub-react';
export const API_URL = process.env.REACT_APP_CHATING_API_URL;

//import { responnse } from "express";
const Join = (props) => {
	const [initialized, setInitialized] = useState(false); //초기화 여부
	const [streamer, setStreamer] = useState([]); //현재 선택된 스트리머
	const [message, setMessage] = useState(""); //현재 입력중인 메세지
	const [messages, setMessages] = useState([]); //채팅방 메세지 목록들
	const [devLogBox, setDevLogBox] = useState([]);
	const [users, setUsers] = useState([]);
	const [showInfoHelper, setShowInfoHelper] = useState(false);
	const [showChatRoom, setShowChatRoom] = useState(true);
	const [mediaSize, setMediaSize] = useState(true);
	const [isActive, setIsActive] = useState(false);
	const [isChatFontZoom, setIsChatFontZoom] = useState(false);
	const [lastMessagesTimestamps, setLastMessagesTimestamps] = useState({ timestamps: [], blockedUntil: null });
	const [channel, setChannel] = useState('bitcoin24');
	const [earliestTimetoken, setEarliestTimetoken] = useState(null);
	const [count, setCount] = useState(50)

	let maxCount = 600;

	const pubnub = usePubNub();

	const UI = { setDevLogBox, setMessages, setUsers };
	var text = `공식 운영시간\r\n"20:30 - 02:30"\r\n\r\n미장서머타임 적용시\r\n"21:30 - 03:00"\r\n\r\n※ 시장변동성에 따라 운영시간이 변경될 수 있으며, 스트리머들의 포지션 변경에 따라 딜레이가 발생할 수 있습니다.`;
	let StreamerName = document.getElementsByClassName("streamerPosition-contents");

	useEffect(() => {
		setShowChatRoom(props.showChatRoom);
	}, [props.showChatRoom]);

	useEffect(() => {
		setMediaSize(props.mediaSize);
	}, [props.mediaSize]);

	useEffect(() => {
		if (props.data === false) return;
		setStreamer(props.data.streamer);
		setInitialized(true);
	}, [props.data]);

	useEffect(() => {
		action.initializeAction();
		let uuid = localStorage.getItem("uuid");

		pubnub.subscribe({ 
			channels: [channel] ,
			withPresence: true
		});
	
		const listener = {
			message: (msg) => { 
				 if(messages != []){
				 	setMessages((prev) => [...prev, msg]);
				 }
			},
			presence: (presenceEvent) => { 
				console.log("presenceEvent : ",presenceEvent.occupancy);
			},
		};

		//이전 메세지 불러오기
		pubnub.fetchMessages({ 
			channels: [channel],
			count: 50, 
		  }, function(status, response) {
			if (status.error) {
				return
			} else {
				if(response.channels[channel]){
					setMessages(response.channels[channel])
					setEarliestTimetoken(response.channels[channel][0].timetoken);	
				}
			}
		});

		pubnub.addListener(listener);

		return () => {
			pubnub.unsubscribeAll();
			pubnub.removeListener(listener);
		};
		
	}, []);

	const chatingMessage = (message) => {
		if (message.length > 150) {
			return;
		} else {
			setMessage(message);
		}
	};

	const display = (showChatRoom, mediaSize) => {
		if (showChatRoom === false) {
			if (mediaSize === "xlarge") {
				return "hide";
			} else if (mediaSize === "large" || mediaSize === "mxlarge") {
				return "hide";
			} else {
				return "";
			}
		} else {
			return "";
		}
	};

	const displayBox = (showChatRoom, mediaSize) => {
		if (showChatRoom === false) {
			if (mediaSize === "xlarge") {
				return "ContainerBox";
			} else if (mediaSize === "large") {
				props.setChattingStyle("chatting-roomCover hide");
				return "hide";
			} else {
				props.setChattingStyle("chatting-roomCover hide");
				return "hide";
			}
		} else {
			props.setChattingStyle("chatting-roomCover");
			return "ContainerBox";
		}
	};

	const streamerClick = (setIsActive, isActive) => {
		setIsActive(!isActive);
	};

	//추가 메세지 로드
	const fetchMoreMessages = () => {
		const options = {
			channels: [channel],
			count: 50,
		};

		if (earliestTimetoken) {
			options.start = earliestTimetoken;
		}

		//500개 이상 불러올 시 리턴
		if(count < maxCount){
			pubnub.fetchMessages(options, function(status, response) {
				if (status.error) {
					return
				} else {
				const historyMessages = response.channels[channel];
				if (historyMessages && historyMessages.length > 0) {
					setMessages(prevMessages => [...historyMessages, ...prevMessages]);
					setEarliestTimetoken(historyMessages[0].timetoken);
				}
				}
			});	
			let newCount = count + 2
			setCount(newCount)	
		}
		
	}

	//showChatRoom == false 이면 안보여야함 하지만 PC일땐 보여야함
	return (
		// <div className={displayBox}>
		<div className={displayBox(showChatRoom, props.mediaSize)}>
			<InfoBar
				showChatRoom={showChatRoom}
				setShowChatRoom={props.setShowChatRoom}
				room={property.room}
				connectionInfo={property.connectionInfo}
				mediaSize={mediaSize}
				nickName={property.connectionInfo.name}
				setNickname={props.setNickname}
				setPopupinfo={props.setPopupinfo}
				setShoInfoHelper={props.setShoInfoHelper}
				showInfoHelper={showInfoHelper}
				UI={UI}
				isChatFontZoom={isChatFontZoom}
				setIsChatFontZoom={setIsChatFontZoom}
			/>
			{/* pc에서 채팅방이 열려있지 않은 경우: .hide 추가 */}
			{/* <div className={!showChatRoom && (props.mediaSize ==='large' || props.mediaSize ==='xlarge')? "" : "hide"}> */}
			<div className={display(showChatRoom, props.mediaSize)}>
				<div className="streamerPosition">
					<div className="streamerPosition-titleRow">
						<div className="title">스트리머 포지션</div>
						<span
							className="material-symbols-outlined"
							onClick={() => {
								props.help2(props.setShoInfoHelper);
							}}
						>
							help
						</span>
						{/* 팝업 위치 표시를 위한 태그 */}
						{/* {showInfoHelper?<div className="popupPosition"><Popup title='도움말' type='help2' helpText={text} okAction={()=>setShowInfoHelper(false)}/></div>:<></>} */}
					</div>
					{/* 스트리머 포지션 열려있는 경우: <div className="streamerPosition-contents active"> */}
					<div className={isActive ? "streamerPosition-contents active" : "streamerPosition-contents"}>
						<div className="list-box">
							{streamer.map((stream, index) => (
								<div key={index} className="list">
									<span className="streamerName">{initialized ? stream.name : "name"}</span>
									<div className="state">
										<span>{initialized ? (stream.price === "" ? "" : Number(stream.price).toLocaleString()) : ""} / </span>
										<span className={stream.position.toLowerCase()}>{initialized ? stream.position : "hedging"}</span>
									</div>
								</div>
							))}
						</div>
						{/* <button type="button" className="chatBtn" onClick={() => streamerClick(setIsActive, isActive)}>
							<span className="material-symbols-outlined">expand_more</span>
						</button> */}
						<button type="button" className="chatBtn" onClick={() => streamerClick(setIsActive, isActive)}>
							<span className="clickBtn">click</span>
						</button>
					</div>
				</div>
				{/* <div className="chatContainer">
					<Messages messages={messages} isChatFontZoom={isChatFontZoom} socketid={property.connectionInfo.socketid} />
					<Input message={message} setMessage={chatingMessage} sendMessage={(event) => action.sendMessage(event, message, chatingMessage, lastMessagesTimestamps, setLastMessagesTimestamps)} />
				</div> */}
				<div className="chatContainer">
					<Messages messages={messages} isChatFontZoom={isChatFontZoom} socketid={property.connectionInfo.socketid} readmore={fetchMoreMessages}/>
					<Input
						message={message}
						setMessage={chatingMessage}
						sendMessage={(event) => action.sendBirdSendMessage(event, channel, pubnub, messages, setMessage, setMessages, message,lastMessagesTimestamps, setLastMessagesTimestamps)}
					/>
				</div>
			</div>
		</div>
	);
};

export default Join;
