import React from "react";
import ReactEmoji from "react-emoji";

// const Message = ({ message: { text, uniqueKey, id, nickName, time }, isChatFontZoom, socketid, previousMessageUserId, previousMessageUserNickname }) => {
const Message = ({ message: { 
   		 message: { message:text, nickname:nickname, uuid:id},
		timetoken: time,
    }, isChatFontZoom, socketid, previousMessageUserId, previousMessageUserNickname }) => {
	let isSentByCurrentUser = false;
	const trimmedId = socketid === undefined || socketid === "" ? "Anonymous" : socketid.trim().toLowerCase();
	const uuid = localStorage.getItem("uuid");
	if (id.toLowerCase() === uuid) {
		isSentByCurrentUser = true;
	}
	
	const utcTime = new Date(parseInt(time) / 10000); // 입력된 시간을 밀리초 단위로 변환하여 UTC 시간으로 변환
	utcTime.setUTCHours(utcTime.getUTCHours()); // UTC 시간에 9시간을 더하여 한국 시간으로 변환
	const formattedTime = utcTime.toLocaleTimeString('KR', { hour: '2-digit', minute: '2-digit' }); // 한국 시간으로 형식화
	//const koreaTime = new Date((parseInt(time) / 10000) + 9 * 60 * 60 * 1000);
	//const formattedTime = new Date((parseInt(time) / 10000) + 9 * 60 * 60 * 1000).toLocaleTimeString('KR', { hour: '2-digit', minute: '2-digit' });
	//const formattedTime = koreaTime.toISOString().replace(/T/, ' ').replace(/\..+/, '');

	const shouldRenderId = id !== previousMessageUserId;
	const shouldRenderNickname = nickname !== previousMessageUserNickname;
	let lastThreeChars = id.slice(-3);

	return isSentByCurrentUser ? (
		<div className="messageContainer justifyEnd">
			<div className="time">{formattedTime}</div>
			<div className="messageBox">
				{/* 기본 폰트 사이즈노출: <p className="messageText"> / 크게보기 클릭 시: <p className="messageText zoomIn"> */}
				<p className={!isChatFontZoom ? "messageText zoomIn" :"messageText"}>{ReactEmoji.emojify(text.toString())}</p>
				{/* <span className="time">{ReactEmoji.emojify(text)}</span> */}
			</div>
		</div>
	) : (
		// 동일유저가 연속으로 채팅을 친 경우 닉네임이 안보여야함!
		<div className="messageContainer justifyStart">
			{(shouldRenderId || shouldRenderNickname) && <div className="sentTextUserNickname">{nickname} {lastThreeChars}</div>}
			<div className="d-flex">
			<div className="time">{formattedTime}</div>
				<div className="messageBox">
					{/* 기본 폰트 사이즈노출: <p className="messageText"> / 크게보기 클릭 시: <p className="messageText zoomIn"> */}
					<p className={!isChatFontZoom ? "messageText zoomIn" :"messageText"}>{ReactEmoji.emojify(text.toString())}</p>
					{/* <span className="time">{ReactEmoji.emojify(text)}</span> */}
				</div>
			</div>
		</div>
	);
};

export default Message;