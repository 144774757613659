import React, { useState } from 'react';
import { useEffect } from 'react';

const GlobalConext = React.createContext();

export const GlobalProvider = ({children}) => {
    const [isConclusionSound, setIsConclusionSound] = useState(false); // 체결 알람 소리 여부 WhaleConclusion
    const [conclusionVolume, setConclusionVolume] = useState(50); // 체결 볼륨 크기 WhaleConclusion
    const [conclusionPrice, setConclusionPrice] = useState(5); // 체결 기준가 (억) WhaleConclusion
    const [isLiquidationSound, setIsLiquidationSound] = useState(false); // 청산 알람 소리 여부 WhaleLiquidation
    const [liquidationVolume, setLiquidationVolume] = useState(50); // 청산 볼륨 크기 WhaleLiquidation

    useEffect(() => {
        
    }, [conclusionPrice]);
    
    return (
        <GlobalConext.Provider value={{     
            isConclusionSound, setIsConclusionSound,
            conclusionVolume, setConclusionVolume,
            conclusionPrice, setConclusionPrice,
            isLiquidationSound, setIsLiquidationSound,
            liquidationVolume, setLiquidationVolume,
        }}>
            {children}
        </GlobalConext.Provider>
    );
}

export default GlobalConext;