import React, { useState } from 'react';

import * as property from './JoinProperty';
import io from "socket.io-client";
import Join from '.';
import sendBird from '../SendBird';

export const state= {
  io:undefined,
}

function GetGuid() {
	function _s4() {
		return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
	}
	return _s4() + _s4() + _s4() + _s4() + _s4() + _s4() + _s4() + _s4();
}

export const initializeAction =()=>{
  //if (state.io !== undefined) return;

  // property.socket.connection = io(property.ENDPOINT);
  // state.io = property.socket.connection;

  let uuid = "";
  let nickName = "";

  if (localStorage.getItem("uuid") == null) {
      uuid = GetGuid();
      nickName = uuid.substring(0,12);
      localStorage.setItem("uuid", uuid);
      localStorage.setItem("nm", nickName);

      property.setMyNiceName(nickName);
  } else {
      uuid = localStorage.getItem("uuid");
      nickName = localStorage.getItem("nm");
      nickName = nickName.trim() === "" ? uuid : nickName;
      property.setMyNiceName(nickName);
  }

  // state.io.emit('sendParameter', { uuid, nickName });

  //   // 'visibilitychange' 이벤트 리스너 추가
  //   document.addEventListener("visibilitychange", function() {
    
  //     if (document.visibilityState === 'visible') {
  //       state.io.disconnect();
  //       property.socket.connection = io(property.ENDPOINT, {autoConnect: true});
  //       state.io = property.socket.connection;
  //       state.io.emit('sendParameter', { uuid, nickName });

  //       // 필요한 이벤트 리스너를 다시 설정
  //       //접속
  //       state.io.on("welcome", (message) => {
  //         UI.setDevLogBox(messages => [ ...messages, 'UUID : ' + message.socketid ]);
  //         property.connectionInfo.socketid = message.socketid;
  //       })

  //       state.io.on("message", (message) => {
  //         UI.setMessages(messages => [ ...messages, message ]);
  //       })

  //       state.io.on("roomData", ({ users }) => {
  //         UI.setUsers(users);
  //       });
  //     }
  //   });

  // //접속
  // state.io.on("welcome", (message) => {
  //   UI.setDevLogBox(messages => [ ...messages, 'UUID : ' + message.socketid ]);
  //   property.connectionInfo.socketid = message.socketid;
  // })

  // state.io.on("message", (message) => {
  //   UI.setMessages(messages => [ ...messages, message ]);
  // })

  // state.io.on("roomData", ({ users }) => {
  //   UI.setUsers(users);
  // });
}

// export const getRoomList = (setAllUsers)=>{
//     state.io.emit("getAllUser", 'getUserInfo',()=>{});
//     state.io.on("getAllUser", (users) => setAllUsers(users));
// }

export const showChatRoom = (setRoom)=>{  setRoom({}); }


export const setNickName = (nick)=>{
  if (nick) {
    property.setMyNiceName(nick);
  }
  localStorage.setItem("nm", nick);
  // sendBird.updateCurrentUserInfo(nick, "", (response, error) => {
  //   if (error) {
  //     return;
  //   }
  //   });
  //state.io= property.socket.connection;
  // state.io.emit("setNickName", nick,()=>{});
}

export const sendMessage = (event,message,setMessage, lastMessagesTimestamps, setLastMessagesTimestamps) => {

  event.preventDefault();
  if(message) {
    let currentTime = Date.now(); // 현재시간

     // 채팅 가능시간이 현재시간보다 이후일 경우
     if (lastMessagesTimestamps.blockedUntil && currentTime < lastMessagesTimestamps.blockedUntil) {
      alert("메세지를 잠시 후 보내주세요.");
      return;
    }

    let newTimestamps = [...lastMessagesTimestamps.timestamps, currentTime];

    // Maintain only the last 4 timestamps
    if (newTimestamps.length > 5) {
      newTimestamps.shift();
    }

    // Check if the user has sent 4 messages within 4 seconds
    if (
      newTimestamps.length === 5 &&
      currentTime - newTimestamps[0] <= 4000
    ) {
      // If so, block for 15 seconds
      setLastMessagesTimestamps({
        timestamps: newTimestamps,
        blockedUntil: currentTime + 15000
      });
      alert("메세지를 잠시 후 보내주세요.");
      return;
    }

    // If not blocked, update the last messages timestamps
    setLastMessagesTimestamps({
      timestamps: newTimestamps,
      blockedUntil: null
    });

    // let initialTime = localStorage.getItem("initial_time") === null ? currentTime : parseInt(localStorage.getItem("initial_time")); // 채팅 가능시간
    // let spammingTime = localStorage.getItem("spamming_time") === null ? currentTime : parseInt(localStorage.getItem("spamming_time")); // 도배 기준 시간
    // let chattingCount = localStorage.getItem("chatting_count") === null ? 0 : parseInt(localStorage.getItem("chatting_count")); // 채팅 카운트
    
    //  // 채팅 가능시간이 현재시간보다 이후일 경우
    //  if (currentTime < initialTime) {
    //   alert("메세지를 잠시 후 보내주세요.");
    //   return;
    // }

    // // 채팅 카운트 증가
    // chattingCount++;

    // // 4초 이후
    // if (currentTime > spammingTime + 4000) {
    //   localStorage.setItem("spamming_time", currentTime); // 도배 기준 시간 초기화
      
    //   // 4회 미만일 경우
    //   if (chattingCount <= 4) { 
    //     localStorage.setItem("chatting_count", chattingCount); // 채팅 카운트 설정
    //   }
    // }

    // // 도배 기준 시간 판단
    // if (chattingCount > 4 && currentTime <= spammingTime + 4000) { // 4초 이내일 경우
    //   localStorage.setItem("initial_time", currentTime + 15000); // 15초 뒤에 채팅 가능
    //   localStorage.setItem("chatting_count", "0"); // 채팅 카운트 초기화
    //   alert("메세지를 잠시 후 보내주세요.");
    //   return;
    // } 

    // state.io.emit('sendMessage', message, () => setMessage(''));
  }
}

export const sendBirdSendMessage = (event, channel, pubnub, messages, setMessage, setMessages, message,lastMessagesTimestamps, setLastMessagesTimestamps) => {
  event.preventDefault();
  if(message) {
    let currentTime = Date.now(); // 현재시간

     // 채팅 가능시간이 현재시간보다 이후일 경우
     if (lastMessagesTimestamps.blockedUntil && currentTime < lastMessagesTimestamps.blockedUntil) {
      alert("메세지를 잠시 후 보내주세요.");
      return;
    }

    let newTimestamps = [...lastMessagesTimestamps.timestamps, currentTime];

    // Maintain only the last 4 timestamps
    if (newTimestamps.length > 5) {
      newTimestamps.shift();
    }

    // Check if the user has sent 4 messages within 4 seconds
    if (
      newTimestamps.length === 5 &&
      currentTime - newTimestamps[0] <= 4000
    ) {
      // If so, block for 15 seconds
      setLastMessagesTimestamps({
        timestamps: newTimestamps,
        blockedUntil: currentTime + 15000
      });
      alert("메세지를 잠시 후 보내주세요.");
      return;
    }

    // If not blocked, update the last messages timestamps
    setLastMessagesTimestamps({
      timestamps: newTimestamps,
      blockedUntil: null
    });

    let initialTime = localStorage.getItem("initial_time") === null ? currentTime : parseInt(localStorage.getItem("initial_time")); // 채팅 가능시간
    let spammingTime = localStorage.getItem("spamming_time") === null ? currentTime : parseInt(localStorage.getItem("spamming_time")); // 도배 기준 시간
    let chattingCount = localStorage.getItem("chatting_count") === null ? 0 : parseInt(localStorage.getItem("chatting_count")); // 채팅 카운트
    
     // 채팅 가능시간이 현재시간보다 이후일 경우
     if (currentTime < initialTime) {
      alert("메세지를 잠시 후 보내주세요.");
      return;
    }

    // 채팅 카운트 증가
    chattingCount++;

    // 4초 이후
    if (currentTime > spammingTime + 4000) {
      localStorage.setItem("spamming_time", currentTime); // 도배 기준 시간 초기화
      
      // 4회 미만일 경우
      if (chattingCount <= 4) { 
        localStorage.setItem("chatting_count", chattingCount); // 채팅 카운트 설정
      }
    }

    // 도배 기준 시간 판단
    if (chattingCount > 4 && currentTime <= spammingTime + 4000) { // 4초 이내일 경우
      localStorage.setItem("initial_time", currentTime + 15000); // 15초 뒤에 채팅 가능
      localStorage.setItem("chatting_count", "0"); // 채팅 카운트 초기화
      alert("메세지를 잠시 후 보내주세요.");
      return;
    }

    let uuid = localStorage.getItem("uuid");
    let nickname = property.connectionInfo.name

    const messageObject = {
      message,
      uuid : uuid,
      nickname: nickname,
    };

    const metadata = {
      userId: pubnub.getUserId(),
    }

    pubnub.publish({ channel: channel, message:messageObject, meta:metadata  }).then((response) => {
      messageObject.timetoken = response.timetoken
      setMessage("");
    })
    .catch((error) => {
      return
    });
//   channel.sendUserMessage(message, (message, error) => {
//     if (error) {
//       return;
//     }
//     setMessage("");
//     setMessages([...messages, message])
//  });
  
};
}