import { useState, useRef, useEffect } from "react";
import "../../css/scss/component/popup.scss";

function Help (props) {
    const [isDarkMode, setIsDarkMode] = useState(true);

    return (
        <div className="popup-bg">
            <div className={isDarkMode ? "popup theme_drk" : "popup theme_lgt"}>
                <div className="popupHeader">
                    <h2>{props.title}</h2>
                </div>
                <div className="popupbody typeHelp">
                    { props.helpText.split('\r\n').map((line, i) => {
                        return (<span key={i}>{line}<br /></span>)
                    })
                    }
                </div>
                <div className="popupFooter">
                    <button 
                        type="button"
                        className="popupBtn" 
                        onClick={()=>{props.okAction()}}
                    >
                    확인
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Help;