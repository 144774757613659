import { useState, useRef, useEffect } from "react";
import "../../css/scss/component/popup.scss";

function Alarm (props) {
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [isSound, setIsSound] = useState(props.isCompleteSound);
    const [volume, setVolume] = useState(props.volume);
    const [standard, setStandard] = useState(props.standardPrice);

    const handleSave = () => {
        // 설정값을 저장하는 로직을 여기에 구현합니다.
        
    };

    return (
        <div className="popup-bg">
            <div className={isDarkMode ? "popup theme_drk" : "popup theme_lgt"}>
                <div className="popupHeader">
                    <h2>{props.title}</h2>
                </div>
                <div className="popupbody typeAlarm">
                    <div className="row">
                        <span className="title">소리</span>
                        <label>
                            <input 
                                type="checkbox" 
                                hidden 
                                checked={isSound} 
                                onChange={(e)=>setIsSound(e.target.checked)}/>
                            <span className="slide-btn"></span>
                        </label>
                    </div>
                    <div className="row">
                        <span className="title">볼륨</span>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            //value={props.volume}
                            defaultValue={volume}
                            className="rangeBar rangeValue"
                            onChange={(e) => {setVolume(e.target.value)}}
                        />
                    </div>
                    <div className={props.type === "Alarm2" ? "hide" : "row"}>
                        <span className="title">금액</span>
                        <div className="amountRange">
                            <div className="d-flex">
                                <span>설정값 :</span>
                                    <input 
                                        type="text"
                                        placeholder="5"
                                        value={standard}
                                    />
                                <span>억</span>
                            </div>
                            <input
                                type="range"
                                min="1"
                                max="150"
                                defaultValue={standard}
                                className="rangeBar rangeValue"
                                onChange={(e) => setStandard(e.target.value)}
                            />

                            <div className="d-flex">
                                <span>1 억</span>
                                <span>150 억</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popupFooter">
						<button
							type="button"
							className="popupCancelBtn"
							onClick={()=>{props.cancelAction()}}
                        >
                        취소
						</button>
                        {props.type === 'Alarm1' &&
                            <button 
                                type="button"
                                className="popupBtn" 
                                onClick={()=>{props.okAction(isSound, volume, standard)}}
                            >
                            확인
                            </button>
                        }
                        {props.type === 'Alarm2' &&
                            <button 
                                type="button"
                                className="popupBtn" 
                                onClick={()=>{props.okAction(isSound, volume)}}
                            >
                            확인
                            </button>
                        }
					</div>
            </div>
        </div>
    );
}

export default Alarm;